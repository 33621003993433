import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setProgress, setToast } from "../../redux/slice/system";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { sortableContainer, sortableElement } from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import { TransferAPI } from "../../axios/api";
import moment from "moment";

import { FormInputtext, FormCheckbox } from "../formElement";
import { checkFormUpdate } from "../../service/common";
import { option_function_type } from "../../service/option";

import { Button } from "primereact/button";
import { TabMenu } from "primereact/tabmenu";
import { ConfirmPopup } from "primereact/confirmpopup";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";

export default function FileTransferTemplate(props) {
  const { popPreOption, closePrePop, moduleType, activeModule } = props;
  const dispatch = useDispatch();
  // 確認資訊
  const [confirmOption, setConfirmOption] = useState({});
  const defaultValues = {
    templateName: "",
  };
  // 表單欄位驗證條件
  const schema = yup
    .object({
      templateName: yup.string().required("必填"),
    })
    .required();
  const {
    control, // 將元件註冊至react-hook-form的方法
    formState: { errors, isSubmitted }, // 整個表單的狀態資料 (errors: 驗證錯誤的訊息)
    handleSubmit, // 表單被提交時觸發
    reset, // 重置表單(包含資料、狀態等等...)
    getValues, // 讀取資料
    setValue,
    trigger,
  } = useForm({ defaultValues, resolver: yupResolver(schema) });
  const [setting, setSetting] = useState({
    Controller,
    control,
    setValue,
    trigger,
    isSubmitted,
  });
  // tab
  const [tabItem, setTabItem] = useState([]);
  const [tabActive, setTabActive] = useState(null);
  // 暫存模組名稱
  const [templateTempName, setTemplateTempName] = useState("");
  // 模組資料
  const [templateAllData, setTemplateAllData] = useState([]);
  const [templateData, setTemplateData] = useState([]);

  // 帶入現有模組資料
  useEffect(() => {
    if (popPreOption.existColumns.length > 0) {
      setTemplateAllData([...popPreOption.existColumns]);
    }
  }, [popPreOption]);

  useEffect(() => {
    if (templateAllData.length === 0) return;

    // 設定 Tab
    let tempTab = templateAllData.map((item) => ({
      label: item.name,
      icon: "pi pi-bookmark-fill",
    }));
    setTabItem(tempTab);

    // 第一次
    if (tabActive === null) {
      let matchIdx = tempTab.findIndex(
        (item) =>
          item.label === activeModule[`${popPreOption.module}Template`]?.name
      );
      setTabActive(matchIdx || 0);
    }
  }, [templateAllData]);

  // 設定特定模組資料
  const matchAction = (matchIdx, data = null) => {
    let tempData = data ? data[matchIdx] : templateAllData[matchIdx];
    if (typeof tempData.settings === "string") {
      tempData.settings = JSON.parse(tempData.settings);
    }

    let tempColumns = Object.values(tempData.settings);
    tempColumns.sort(function (pre, next) {
      return pre.order - next.order;
    });

    setTemplateData(tempColumns);
    reset({
      id: tempData.id,
      templateName: tempData.name,
      ...tempData.settings,
    });
  };

  // 新增空白模組
  const addEmptyAction = () => {
    if (templateTempName === "") {
      dispatch(
        setToast({
          severity: "warn",
          summary: "請輸入模組名稱",
          detail: "",
        })
      );
      return;
    }

    let isSame = templateAllData.some((item) => item.name === templateTempName);
    if (isSame) {
      dispatch(
        setToast({
          severity: "warn",
          summary: "模組名稱重複",
          detail: "",
        })
      );
      return;
    }

    // 設定 Tab
    let tempTab = [...tabItem];
    tempTab.push({
      label: templateTempName,
      icon: "pi pi-bookmark-fill",
    });
    setTabItem(tempTab);
    setTabActive(tempTab.length - 1);

    // 設定模組資料
    let entries = Object.entries(popPreOption.defaultColumns);
    let tempModuleData = {};
    entries.forEach(([key, val]) => {
      if (!val.isShow) return;

      tempModuleData[key] = {
        apiKey: val.apiKey,
        name: val.name,
        value: val.value,
        checked: val.checked,
        order: val.order,
      };
    });
    let temp = [...updateTemplate()];
    temp.push({
      id: `add_${moment().format("YYYY/MM/DD HH:mm:ss")}`,
      name: templateTempName,
      settings: tempModuleData,
    });
    setTemplateAllData(temp);

    // 清空新增模組名稱
    setTemplateTempName("");
  };

  // 更新暫存模組資料
  const updateTemplate = () => {
    let submitData = getValues();

    // 重新排序
    templateData.forEach((item, index) => {
      submitData[item.apiKey].order = index + 1;
    });

    // 更新資料
    let tempTemplate = {
      id: submitData.id,
      name: submitData.templateName,
      settings: {
        ...submitData,
      },
    };
    delete tempTemplate.settings.id;
    delete tempTemplate.settings.templateName;

    let temp = [...templateAllData];
    let matchIdx = temp.findIndex((item) => item.id == submitData.id);
    if (matchIdx !== -1) {
      temp[matchIdx] = tempTemplate;
    }
    return temp;
  };

  // 改變排序
  const onSortEnd = ({ oldIndex, newIndex }) => {
    let temp = arrayMoveImmutable(templateData, oldIndex, newIndex);
    setTemplateData(temp);
  };

  // tab 切換
  const tabChange = (tabIndex) => {
    // trigger();
    setTemplateAllData([...updateTemplate()]);

    setTabActive(tabIndex);
  };

  useEffect(() => {
    if (tabActive === null) return;

    // 設定模組資料
    matchAction(tabActive);
  }, [tabActive]);

  // 勾選變更
  const changeChecked = (e, index) => {
    let submitData = getValues();
    let { checked } = e,
      apiKey = templateData[index].apiKey,
      temp = [...templateData],
      data = { ...submitData[apiKey] };

    if (checked) {
      // 將勾選的項目順序移至勾選清單的最後一位
      temp.splice(index, 1);

      let matchIndex = temp
        .map((item) => {
          return item.checked;
        })
        .indexOf(false);

      temp.splice(matchIndex, 0, data);
    } else {
      // 將取消勾選的項目順序移至全部清單的最後一位
      temp.splice(index, 1);
      temp.push(data);
    }

    setTemplateData(temp);
  };

  useEffect(() => {
    setSetting((state) => ({
      ...state,
      isSubmitted,
    }));
  }, [isSubmitted]);

  // 表單驗證成功後，呼叫 API
  const onSubmit = (submitData) => {
    dispatch(setProgress(true));

    // test
    // console.log([...updateTemplate()]);
    // return;

    let temp = [...updateTemplate()];

    saveAction({ submitData: temp, index: 0, count: temp.length });
  };

  const saveAction = ({ submitData, index, count }) => {
    let isAdd = String(submitData[index].id).indexOf("add_") !== -1;

    // if (
    //   !isAdd &&
    //   !checkFormUpdate({
    //     newData: submitData[index],
    //     oldData: templateAllData[index],
    //   })
    // ) {
    //   index++;
    //   if (index < count) {
    //     saveAction({ submitData, index, count });
    //   } else {
    //     closePrePop({ type: "edit" });
    //     popPreOption.reGetTemplate();
    //   }
    //   return;
    // }

    // 沒有變更
    if (typeof submitData[index].settings === "string") {
      index++;
      if (index < count) {
        saveAction({ submitData, index, count });
      } else {
        closePrePop({ type: "edit" });
        popPreOption.reGetTemplate();
      }
      return;
    }

    let promise,
      jsonData = {
        name: submitData[index].name,
        settings:
          typeof submitData[index].settings === "string"
            ? submitData[index].settings
            : JSON.stringify(submitData[index].settings),
      };
    if (isAdd) {
      // 新增
      jsonData = {
        ...jsonData,
        function_type: option_function_type[moduleType],
        is_use: 0,
        type: popPreOption.module === "import" ? 1 : 2,
      };

      promise = TransferAPI.setExportImportModule({
        data: jsonData,
      });
    } else {
      // 修改
      jsonData.id = submitData[index].id;
      promise = TransferAPI.updateExportImportModule({
        data: jsonData,
      });
    }

    promise.then((result) => {
      let { Response, message } = result;

      if (Response === 1) {
        index++;
        if (index < count) {
          saveAction({ submitData, index, count });
        } else {
          closePrePop({ type: "edit" });
          popPreOption.reGetTemplate();
        }
      } else {
        dispatch(
          setToast({
            severity: "error",
            summary: message ?? "編輯失敗",
            detail: "",
          })
        );
        dispatch(setProgress(false));
      }
    });
  };

  // 刪除模板
  const deleteAction = () => {
    dispatch(setProgress(true));

    let temp = [...templateAllData],
      data = templateAllData[tabActive],
      jsonData = { id: data.id };

    if (String(data.id).indexOf("add_") !== -1) {
      temp.splice(tabActive, 1);
      setTemplateAllData(temp);

      let index = tabActive - 1 < 0 ? 0 : tabActive - 1;
      setTabActive(index);
      if (temp.length === 0) {
        setTabItem([]);
        setTemplateData([]);
      } else {
        matchAction(index, temp);
      }
      dispatch(setProgress(false));
      return;
    }

    TransferAPI.deleteExportImportModule({
      data: jsonData,
    }).then((result) => {
      let { Response, message } = result;

      if (Response === 1) {
        temp.splice(tabActive, 1);
        setTemplateAllData(temp);

        let index = tabActive - 1 < 0 ? 0 : tabActive - 1;
        setTabActive(index);
        if (temp.length === 0) {
          setTabItem([]);
          setTemplateData([]);
        } else {
          matchAction(index, temp);
        }
      } else {
        dispatch(
          setToast({
            severity: "error",
            summary: message ?? "刪除失敗",
            detail: "",
          })
        );
      }
      dispatch(setProgress(false));
    });
  };

  return (
    <>
      <ConfirmPopup
        {...confirmOption}
        onHide={() =>
          setConfirmOption((state) => ({ ...state, visible: false }))
        }
      />

      <div className="p-3">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex align-items-center mt-2">
            <InputText
              value={templateTempName}
              placeholder="template name"
              onChange={(e) => setTemplateTempName(e.target.value)}
            />
            <Button
              type="button"
              label="Add Template"
              className="px-3"
              onClick={() => addEmptyAction()}
            />
          </div>

          <div className="grid mt-3">
            {templateData && templateData.length > 0 && (
              <>
                <TabMenu
                  className="w-full mt-3"
                  model={tabItem}
                  activeIndex={tabActive}
                  onTabChange={(e) => tabChange(e.index)}
                />

                <div className="col-12 flex align-items-center mt-2">
                  <label
                    htmlFor="templateName"
                    className={classNames(
                      { "p-error": errors.templateName },
                      "mr-2 mb-0"
                    )}
                  >
                    Template Name
                  </label>
                  <div className="w-full mr-2" style={{ maxWidth: "20rem" }}>
                    <FormInputtext
                      setting={setting}
                      data={{ name: "templateName" }}
                    />
                  </div>
                  <Button
                    id="button_delete"
                    type="button"
                    label="Delete"
                    className="p-button-danger px-3 ml-auto"
                    onClick={() =>
                      setTimeout(() => {
                        setConfirmOption({
                          target: document.getElementById("button_delete"),
                          message: "確認是否刪除模板?",
                          icon: "pi pi-exclamation-triangle",
                          visible: true,
                          acceptLabel: "是",
                          rejectLabel: "否",
                          accept: () => deleteAction(),
                          reject: () => null,
                        });
                      }, 200)
                    }
                  />
                </div>

                <div className="col-7">
                  <div className="border-1 border-solid border-300 border-round-xs">
                    {templateData.map((item, index) => (
                      <div
                        key={`column_edit_${index}`}
                        className={classNames(
                          {
                            hidden: !(
                              popPreOption.defaultColumns[item.apiKey]
                                ?.isShow ?? true
                            ),
                            flex:
                              popPreOption.defaultColumns[item.apiKey]
                                ?.isShow ?? true,
                          },
                          "align-items-center mx-1 my-2"
                        )}
                      >
                        <label className="w-12rem text-right">
                          {item.name}：
                        </label>
                        <FormCheckbox
                          setting={setting}
                          data={{
                            name: `${item.apiKey}.checked`,
                            label: "",
                            type: "boolean",
                            value: true,
                            changeFun: (e) => changeChecked(e, index),
                          }}
                        />
                        <FormInputtext
                          key={index}
                          setting={setting}
                          data={{ name: `${item.apiKey}.value` }}
                        />
                      </div>
                    ))}
                  </div>
                </div>
                <div className="col-5">
                  <div className="border-1 border-solid border-300 border-round-xs">
                    <SortableContainer onSortEnd={onSortEnd}>
                      {templateData.map((item, index) => (
                        <SortableItem
                          key={`column_sort_${index}`}
                          className={classNames({
                            hidden: !(
                              popPreOption.defaultColumns[item.apiKey]
                                ?.isShow ?? true
                            ),
                          })}
                          index={index}
                          sortIndex={index}
                          value={getValues(item.apiKey)?.value}
                        />
                      ))}
                    </SortableContainer>
                  </div>
                </div>

                <div className="col-12 flex mt-2">
                  <Button
                    type="button"
                    label="Close"
                    className="p-button-secondary p-button-outlined ml-auto px-3"
                    onClick={() => closePrePop({ type: "edit" })}
                  />
                  <Button type="submit" label="Save" className="ml-2 px-3" />
                </div>
              </>
            )}
          </div>
        </form>
      </div>
    </>
  );
}

const SortableContainer = sortableContainer(({ children }) => {
  return <div className="sort-wrap">{children}</div>;
});

const SortableItem = sortableElement(({ className, value }) => {
  return <div className={classNames(className, "sort-item")}>{value}</div>;
});
