export const vcImportColumns = {
  po: {
    name: "PO",
    value: "PO",
    apiKey: "po",
    uiType: "string",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: false,
    required: true,
    order: 1,
  },
  vendor: {
    name: "Vendor",
    value: "Vendor",
    apiKey: "vendor",
    uiType: "string",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: false,
    required: true,
    order: 2,
  },
  ship_to_location: {
    name: "Ship to location",
    value: "Ship to location",
    apiKey: "ship_to_location",
    uiType: "string",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: false,
    required: true,
    order: 3,
  },
  asin: {
    name: "ASIN",
    value: "ASIN",
    apiKey: "asin",
    uiType: "string",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: false,
    required: true,
    order: 4,
  },
  external_id: {
    name: "External ID",
    value: "External ID", // 改抓 Model Number (暫不改)
    apiKey: "external_id",
    uiType: "string",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: false,
    required: true,
    order: 5,
  },
  external_id_type: {
    name: "External Id Type",
    value: "External Id Type",
    apiKey: "external_id_type",
    uiType: "string",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: false,
    required: true,
    order: 6,
  },
  model_number: {
    name: "Model Number",
    value: "Model Number",
    apiKey: "model_number",
    uiType: "string",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: false,
    required: true,
    order: 7,
  },
  // title: {
  //   name: "Title",
  //   value: "Title",
  //   apiKey: "title",
  //   uiType: "string",
  //   isShow: true,
  //   checked: true,
  //   importDisabled: false,
  //   importUI: false,
  //   required: true,
  //   order: 8,
  // },
  availability: {
    name: "Availability",
    value: "Availability",
    apiKey: "availability",
    uiType: "string",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: false,
    required: true,
    order: 9,
  },
  window_type: {
    name: "Window Type",
    value: "Window Type",
    apiKey: "window_type",
    uiType: "string",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: false,
    required: true,
    order: 10,
  },
  window_start: {
    name: "Window Start",
    value: "Window Start",
    apiKey: "window_start",
    uiType: "date",
    dateFormatUI: "yy/mm/dd",
    dateFormatLabel: "YYYY-MM-DD",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: false,
    required: true,
    order: 11,
  },
  window_end: {
    name: "Window End",
    value: "Window End",
    apiKey: "window_end",
    uiType: "date",
    dateFormatUI: "yy/mm/dd",
    dateFormatLabel: "YYYY-MM-DD",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: false,
    required: true,
    order: 12,
  },
  expected_date: {
    name: "Expected Date",
    value: "Expected Date",
    apiKey: "expected_date",
    uiType: "date",
    dateFormatUI: "yy/mm/dd",
    dateFormatLabel: "YYYY-MM-DD",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: false,
    required: true,
    order: 13,
  },
  quantity_requested: {
    name: "Quantity Requested",
    value: "Quantity Requested",
    apiKey: "quantity_requested",
    uiType: "number",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: false,
    required: true,
    order: 14,
  },
  expected_quantity: {
    name: "Expected Quantity",
    value: "Expected Quantity",
    apiKey: "expected_quantity",
    uiType: "number",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: false,
    required: true,
    order: 15,
  },
  unit_cost: {
    name: "Unit Cost",
    value: "Unit Cost",
    apiKey: "unit_cost",
    uiType: "number",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: false,
    required: true,
    order: 16,
  },
  currency: {
    name: "Currency",
    value: "Currency",
    apiKey: "currency",
    uiType: "string",
    isShow: true,
    checked: true,
    importDisabled: false,
    importUI: false,
    required: true,
    order: 17,
  },
};

export const vcExportColumns = {
  createdatetime: {
    name: "Date",
    value: "Date",
    apiKey: "createdatetime",
    uiType: "date",
    dateFormatUI: "yy/mm/dd",
    dateFormatLabel: "YYYY-MM-DD",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: true,
    width: 100,
    order: 1,
  },
  po: {
    name: "PO",
    value: "PO",
    apiKey: "po",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: true,
    width: 100,
    order: 2,
  },
  warehouse_id: {
    name: "Warehouse",
    value: "Warehouse",
    apiKey: "warehouse_id",
    uiType: "select",
    options: "warehouse",
    optionType: "number",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: true,
    width: 120,
    order: 3,
  },
  status: {
    name: "Status",
    value: "Status",
    apiKey: "status",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: true,
    width: 170,
    order: 4,
  },
  vc_shipping_carrier: {
    name: "Carrier",
    value: "Carrier",
    apiKey: "vc_shipping_carrier",
    uiType: "string", // select
    options: "carrier",
    optionType: "number",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: true,
    width: 200,
    order: 5,
  },
  vc_shipping_tracking: {
    name: "Tracking",
    value: "Tracking",
    apiKey: "vc_shipping_tracking",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: true,
    width: 200,
    order: 6,
  },
  vc_shipping_arn: {
    name: "ARN",
    value: "ARN",
    apiKey: "vc_shipping_arn",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: true,
    width: 100,
    order: 6,
  },
  vc_shipping_asn: {
    name: "ASN",
    value: "ASN",
    apiKey: "vc_shipping_asn",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: true,
    width: 100,
    order: 7,
  },
  shipping_method: {
    name: "Shipping Method",
    value: "Shipping Method",
    apiKey: "shipping_method",
    uiType: "select",
    options: "shipping_method",
    optionType: "number",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: true,
    width: 150,
    order: 9,
  },
  shipping_unit_qty: {
    name: "Shipping Unit",
    value: "Shipping Unit",
    apiKey: "shipping_unit_qty",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: true,
    width: 120,
    order: 10,
  },
  vc_inventoryList: {
    name: "Pallet Number",
    value: "Pallet Number",
    apiKey: "vc_inventoryList",
    uiType: "pallet",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: true,
    width: 330,
    order: 11,
  },
  notes: {
    name: "Notes",
    value: "Notes",
    apiKey: "notes",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: true,
    required: true,
    width: 120,
    order: 12,
  },
  vendor: {
    name: "Vendor",
    value: "Vendor",
    apiKey: "vendor",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: true,
    width: 100,
    order: 13,
  },
  asin: {
    name: "ASIN",
    value: "ASIN",
    apiKey: "asin",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: true,
    width: 100,
    order: 14,
  },
  sku: {
    name: "SKU",
    value: "SKU",
    apiKey: "sku",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: true,
    width: 200,
    order: 15,
  },
  window_start: {
    name: "WindowStart",
    value: "WindowStart",
    apiKey: "window_start",
    uiType: "date",
    dateFormatUI: "yy/mm/dd",
    dateFormatLabel: "YYYY-MM-DD",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: true,
    width: 110,
    order: 16,
  },
  window_end: {
    name: "WindowEnd",
    value: "WindowEnd",
    apiKey: "window_end",
    uiType: "date",
    dateFormatUI: "yy/mm/dd",
    dateFormatLabel: "YYYY-MM-DD",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: true,
    width: 110,
    order: 17,
  },
  expected_date: {
    name: "Expected Date",
    value: "Expected Date",
    apiKey: "expected_date",
    uiType: "date",
    dateFormatUI: "yy/mm/dd",
    dateFormatLabel: "YYYY-MM-DD",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: true,
    width: 130,
    order: 18,
  },
  quantity_requested: {
    name: "Request Qty",
    value: "Request Qty",
    apiKey: "quantity_requested",
    uiType: "number",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: true,
    width: 120,
    order: 19,
  },
  expected_quantity: {
    name: "Expected Qty",
    value: "Expected Qty",
    apiKey: "expected_quantity",
    uiType: "number",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: true,
    width: 120,
    order: 20,
  },
  unit_cost: {
    name: "VC Price",
    value: "VC Price",
    apiKey: "unit_cost",
    uiType: "number",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: true,
    width: 100,
    order: 21,
  },
  mancr_price: {
    name: "Mancr Price",
    value: "Mancr Price",
    apiKey: "mancr_price",
    uiType: "number",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: true,
    width: 120,
    order: 22,
  },
  oms_code: {
    name: "OMS",
    value: "OMS",
    apiKey: "oms_code",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: true,
    width: 150,
    order: 23,
  },
  checking_price: {
    name: "Checking Price",
    value: "Checking Price",
    apiKey: "checking_price",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: true,
    width: 130,
    order: 24,
  },
  is_checking_instock: {
    name: "Checking Instock",
    value: "Checking Instock",
    apiKey: "is_checking_instock",
    uiType: "string",
    isShow: true,
    checked: true,
    listDisabled: false,
    listUI: false,
    required: true,
    width: 140,
    order: 25,
  },
};
