import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setProgress, setToast } from "../../redux/slice/system";
import { CommonAPI, InventoryAPI } from "../../axios/api";
import { useAbortedEffect } from "../../components/hooks";
import { AxiosMulti } from "../../axios/request/multi";
import axios from "axios";

import ModuleBreadCrumb from "../../components/module/breadCrumb";
import ModuleTableEmpty from "../../components/module/table/empty";
import ModuleTableSort, {
  formatTbSort,
} from "../../components/module/table/sort";
import { system_table, system_dropdown } from "../../service/system";
import {
  getPageSetting,
  setPageSetting,
  formatOptions,
} from "../../service/common";
import {
  createColumns,
  createSchema,
  ModuleTablePaginator,
} from "../../service/table";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { TabView, TabPanel } from "primereact/tabview";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";

export default function InventoryWarehouse(props) {
  const dispatch = useDispatch();
  const crumbItem = [{ label: "Inventory" }, { label: "Warehouse" }];
  const [reload, setReload] = useState({ list: false });
  const [warehouseExportColumns, setWarehouseExportColumns] = useState({});
  // 列表資料
  const [rawData, setRawData] = useState([]);
  const [tbData, setTbData] = useState([]);
  const [tbTotal, setTbTotal] = useState(0);
  const [tbFirstIndex, setTbFirstIndex] = useState(0);
  const [tbRows, setTbRows] = useState(system_table.size);
  const [tbColumns, setTbColumns] = useState([]);
  const [tbSortField, setTbSortField] = useState([]);
  // 篩選條件
  const defaultFilter = {
    page: 1,
    size: system_table.size,
    isTotal: false,
    warehouse_id: null,
    keywords: "",
    // supplier: null,
    // source: null,
  };
  const [filterData, setFilterData] = useState(defaultFilter);
  const [search, setSearch] = useState({});
  // tab
  const [tabData, setTabData] = useState(null);
  const [tabActive, setTabActive] = useState(0);
  // 下拉選單資料
  const [optionData, setOptionData] = useState({});

  useAbortedEffect((signal) => {
    dispatch(setProgress(true));

    let params = {
      ...search,
      page: search.isTotal ? -1 : search.page,
      size: search.isTotal ? -1 : search.size,
    };

    AxiosMulti([
      CommonAPI.getWarehouse({
        data: { page: -1, status: 1 },
        options: { signal },
      }),
    ]).then(
      axios.spread((result1) => {
        if (result1) {
          let { Response, data, total, message } = result1;
          if (Response === 1 && data) {
            let temp = formatOptions(
              {
                warehouse: data,
              },
              { uniqueKey: "id", showLabel: "name" }
            );
            formatOtherOptions(temp);

            let tempTab = data.map((item) => ({
              label: item.name,
              ...item,
            }));
            setTabData(tempTab);
          } else {
            dispatch(
              setToast({
                severity: "error",
                summary: message,
                detail: "",
              })
            );
          }
        }
      })
    );
  }, []);

  const formatOtherOptions = (data) => {
    let suppliers = [
      {
        value: "4",
        label: "Vienlam",
      },
      {
        value: "5",
        label: "Lollicup",
      },
      {
        value: "6",
        label: "JinCheng",
      },
      {
        value: "7",
        label: "XiangYing",
      },
      {
        value: "8",
        label: "Theodoor",
      },
      {
        value: "9",
        label: "TaiCheng",
      },
      {
        value: "10",
        label: "MC Shop Fit",
      },
      {
        value: "11",
        label: "ShapeX",
      },
      {
        value: "12",
        label: "Yovee",
      },
      {
        value: "13",
        label: "Alibaba",
      },
      {
        value: "14",
        label: "Sunyk",
      },
      {
        value: "15",
        label: "Action Sales",
      },
      {
        value: "16",
        label: "Torani",
      },
    ];
    let sources = [
      {
        value: "1456517576460455936",
        label: "Vietnam",
      },
      {
        value: "1456517576624033792",
        label: "USA",
      },
      {
        value: "1456517576624033793",
        label: "China",
      },
      {
        value: "-1",
        label: "Non-US",
      },
    ];

    let temp = formatOptions({
      suppliers: suppliers,
      sources: sources,
    });
    setOptionData({
      ...data,
      ...temp,
    });
  };

  // tab 切換
  const tabChange = (e) => {
    setTabActive(e.index);

    let temp = { ...filterData, warehouse_id: tabData[e.index]?.id };
    setFilterData(temp);
    setSearch(temp);
    setTbFirstIndex(0);
  };

  // 表格頁數切換觸發
  const onPage = (e) => {
    const startIndex = e.first,
      rows = e.rows;

    // 同一頁面，不刷新
    if (startIndex === tbFirstIndex && rows === tbRows) {
      return;
    }

    setTbFirstIndex(startIndex);
    setTbRows(e.rows);

    let temp = {
      ...search,
      page: Math.floor(startIndex / e.rows) + 1,
      size: rows,
      isTotal: rows === tbTotal,
    };

    setFilterData(temp);
    setSearch(temp);
  };

  // 取 該頁搜尋參數
  useEffect(() => {
    if (!tabData) return;

    let result = getPageSetting(["/inventory/warehouse"]);

    setTbFirstIndex(
      result && result.params.page >= 0
        ? (result.params.page - 1) * result.params.size
        : 0
    );
    if (result) {
      setTbRows(result.params.size);
      setFilterData((state) => ({ ...state, ...result.params }));
    }
    setSearch(result ? result.params : filterData);
  }, [tabData]);

  // 更新篩選條件
  const changeFilter = ({ target }) => {
    let { name, value } = target;

    setFilterData((state) => ({
      ...state,
      [name]: value,
    }));
  };

  // 判斷是否搜尋
  useEffect(() => {
    let isSearch = Object.keys(search).length > 0;
    if (!isSearch) {
      return;
    }

    setReload((state) => ({ ...state, list: true }));
  }, [search]);

  // 取得列表資料
  useAbortedEffect(
    (signal) => {
      if (!reload.list) {
        return;
      }

      dispatch(setProgress(true));

      let params = {
        ...search,
        page: -1,
        size: -1,
      };
      if (!params.warehouse_id) params.warehouse_id = tabData[0]?.id;

      setPageSetting({ params, page: "/inventory/warehouse" });

      InventoryAPI.getWarehouseStock({
        data: params,
        options: { signal },
      }).then((result) => {
        let { Response, header = [], data = [], message } = result;

        if (Response === 1) {
          let tempColumns = {},
            fixedColumns = {
              sku: {
                name: "Sku",
                value: "Sku",
                width: 170,
              },
              fnsku: {
                name: "FnSku",
                value: "FnSku",
                width: 150,
              },
              model: {
                name: "Model",
                value: "Model",
                width: 150,
              },
              total_stock: {
                name: "In Stock",
                value: "In Stock",
                width: 90,
              },
              reserved_stock: {
                name: "Reserved",
                value: "Reserved",
                width: 90,
              },
            };
          header.forEach((item, index) => {
            if (item.name === "received_list") return;

            tempColumns[item.name] = {
              name: item.name,
              value: item.name,
              apiKey: item.id ? `ID_${item.id}` : item.name,
              uiType: "string",
              isShow: true,
              checked: true,
              listDisabled: false,
              listUI: false,
              required: false,
              width: 110,
              order: index + 1,
              ...(fixedColumns[item.name] ?? {}),
            };
          });
          setWarehouseExportColumns(tempColumns);

          data.forEach((row) => {
            row.received_list.forEach((item) => {
              row[`ID_${item.id}`] = item.stock;
            });
          });

          setRawData([...data]);
          setTbData([...data]);
          setTbTotal(data.length);
          setTbRows(data.length);
          setReload((state) => ({
            ...state,
            list: false,
          }));
        } else {
          dispatch(
            setToast({
              severity: "error",
              summary: message,
              detail: "",
            })
          );
          dispatch(setProgress(false));
        }
      });
    },
    [reload]
  );

  // 設定列表欄位
  useEffect(() => {
    let entries = Object.values(warehouseExportColumns ?? {});
    if (entries.length === 0) return;

    // 重整排序
    entries.sort(function (pre, next) {
      return pre.order - next.order;
    });

    // 整理表格欄位
    let tempColumns = createColumns({
      type: "list",
      saveType: "no",
      activeTemplate: entries,
      defaultTemplate: warehouseExportColumns,
      frozenSetting: {
        frozen: true,
        align: "left",
        column: ["sku", "fnsku", "model", "total_stock", "reserved_stock"],
      },
      optionData: optionData,
      tbRows,
    });
    setTbColumns(tempColumns);

    setTimeout(() => {
      dispatch(setProgress(false));
    }, tempColumns.length * 10);
  }, [tbData, tbRows, warehouseExportColumns]);

  return (
    <>
      <ModuleBreadCrumb className="mb-3 border-none" crumbItem={crumbItem} />

      <div className="grid mr-0">
        <div className="col-12 sm:col-6 md:col-3 lg:col-2 pr-0">
          <InputText
            name="keywords"
            className="w-full"
            value={filterData.keywords}
            onChange={(e) => changeFilter(e)}
            placeholder="Keyword"
          />
        </div>
        {/* <div className="col-12 sm:col-6 md:col-3 lg:col-2 pr-0">
          <Dropdown
            name="supplier"
            className="w-full"
            value={filterData.supplier}
            onChange={(e) => changeFilter(e)}
            options={optionData.suppliers}
            optionLabel="Code"
            optionValue="Id"
            placeholder="Select Supplier"
            emptyMessage={system_dropdown.empty}
            showClear
          />
        </div>
        <div className="col-12 sm:col-6 md:col-3 lg:col-2 pr-0">
          <Dropdown
            name="source"
            className="w-full"
            value={filterData.source}
            onChange={(e) => changeFilter(e)}
            options={optionData.sources}
            optionLabel="Name"
            optionValue="Id"
            placeholder="Select Source"
            emptyMessage={system_dropdown.empty}
            showClear
          />
        </div> */}
        <div className="flex col-12 sm:col-6 md:col-9 lg:col-10 pr-0">
          <Button
            className="p-button-secondary p-button-icon-only px-4 ml-auto sm:ml-0"
            icon="pi pi-search"
            onClick={() => {
              setSearch((state) => ({ ...state, ...filterData, page: 1 }));
              setTbFirstIndex(0);
            }}
          />
        </div>
      </div>

      <div className="mt-3">
        <TabView
          activeIndex={tabActive}
          onTabChange={(e) => tabChange(e)}
          scrollable
        >
          {tabData?.map((item, index) => (
            <TabPanel key={`tab_item_${index}`} header={item.label}></TabPanel>
          ))}
        </TabView>

        <DataTable
          value={tbData}
          className={classNames("size-sm", {
            "table-empty": tbData.length === 0,
          })}
          size="normal"
          paginator
          paginatorTemplate={ModuleTablePaginator}
          currentPageReportTemplate="Total {totalRecords} items"
          // lazy
          // first={tbFirstIndex}
          // totalRecords={tbTotal}
          // onPage={onPage}
          rows={tbRows}
          scrollable
          scrollHeight="calc(100vh - 20rem)"
          scrollDirection="both"
          emptyMessage={system_table.empty}
        >
          {tbColumns?.map((item, index) => (
            <Column
              key={`${item.field}_${index}`}
              {...item}
              className={classNames(item.className, {
                "surface-100": index % 2 === 1,
              })}
              header={
                <div className="flex align-items-center">
                  {typeof item.header === "function"
                    ? item.header()
                    : item.header}
                  <ModuleTableSort
                    field={item.field}
                    sortField={tbSortField}
                    setSortField={setTbSortField}
                    rawTbData={rawData}
                    tbData={tbData}
                    setTbData={setTbData}
                    sortAction={({ data, rawData, field, setData }) =>
                      formatTbSort({
                        data,
                        rawData,
                        field,
                        setData,
                      })
                    }
                  />
                </div>
              }
            />
          ))}
        </DataTable>

        {tbData.length === 0 && <ModuleTableEmpty />}
      </div>
    </>
  );
}
