import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setProgress, setToast } from "../../redux/slice/system";
import { InventoryAPI } from "../../axios/api";
import { useAbortedEffect } from "../../components/hooks";

import ModuleBreadCrumb from "../../components/module/breadCrumb";
import ModuleTableEmpty from "../../components/module/table/empty";
import ModuleTableSort, {
  formatTbSort,
} from "../../components/module/table/sort";
import { grossExportColumns } from "../../service/columns/inventory/gross";
import { system_table, system_dropdown } from "../../service/system";
import { getPageSetting, setPageSetting } from "../../service/common";
import {
  createColumns,
  createSchema,
  ModuleTablePaginator,
} from "../../service/table";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";

export default function InventoryGross(props) {
  const dispatch = useDispatch();
  const crumbItem = [{ label: "Inventory" }, { label: "Gross" }];
  const [reload, setReload] = useState({ list: false });
  // 列表資料
  const [rawData, setRawData] = useState([]);
  const [tbData, setTbData] = useState([]);
  const [tbTotal, setTbTotal] = useState(0);
  const [tbFirstIndex, setTbFirstIndex] = useState(0);
  const [tbRows, setTbRows] = useState(system_table.size);
  const [tbColumns, setTbColumns] = useState([]);
  const [tbSortField, setTbSortField] = useState([]);
  // 篩選條件
  const defaultFilter = {
    page: 1,
    size: system_table.size,
    isTotal: false,
    supplier: null,
    source: null,
    keywords: "",
  };
  const [filterData, setFilterData] = useState(defaultFilter);
  const [search, setSearch] = useState({});
  const [optionData, setOptionData] = useState({});

  useAbortedEffect((signal) => {
    let suppliers = [
      {
        Id: "4",
        Code: "Vienlam",
      },
      {
        Id: "5",
        Code: "Lollicup",
      },
      {
        Id: "6",
        Code: "JinCheng",
      },
      {
        Id: "7",
        Code: "XiangYing",
      },
      {
        Id: "8",
        Code: "Theodoor",
      },
      {
        Id: "9",
        Code: "TaiCheng",
      },
      {
        Id: "10",
        Code: "MC Shop Fit",
      },
      {
        Id: "11",
        Code: "ShapeX",
      },
      {
        Id: "12",
        Code: "Yovee",
      },
      {
        Id: "13",
        Code: "Alibaba",
      },
      {
        Id: "14",
        Code: "Sunyk",
      },
      {
        Id: "15",
        Code: "Action Sales",
      },
      {
        Id: "16",
        Code: "Torani",
      },
    ];
    let sources = [
      {
        Id: "1456517576460455936",
        Name: "Vietnam",
      },
      {
        Id: "1456517576624033792",
        Name: "USA",
      },
      {
        Id: "1456517576624033793",
        Name: "China",
      },
      {
        Id: "-1",
        Name: "Non-US",
      },
    ];

    setOptionData({
      suppliers: suppliers,
      sources: sources,
    });
  }, []);

  // 表格頁數切換觸發
  const onPage = (e) => {
    const startIndex = e.first,
      rows = e.rows;

    // 同一頁面，不刷新
    if (startIndex === tbFirstIndex && rows === tbRows) {
      return;
    }

    setTbFirstIndex(startIndex);
    setTbRows(e.rows);

    let temp = {
      ...search,
      page: Math.floor(startIndex / e.rows) + 1,
      size: rows,
      isTotal: rows === tbTotal,
    };

    setFilterData(temp);
    setSearch(temp);
  };

  // 取 該頁搜尋參數
  useEffect(() => {
    let result = getPageSetting(["/inventory/gross"]);

    setTbFirstIndex(
      result && result.params.page >= 0
        ? (result.params.page - 1) * result.params.size
        : 0
    );
    if (result) {
      setTbRows(result.params.size);
      setFilterData((state) => ({ ...state, ...result.params }));
    }
    setSearch(result ? result.params : filterData);
  }, []);

  // 更新篩選條件
  const changeFilter = ({ target }) => {
    let { name, value } = target;

    setFilterData((state) => ({
      ...state,
      [name]: value,
    }));
  };

  // 判斷是否搜尋
  useEffect(() => {
    let isSearch = Object.keys(search).length > 0;
    if (!isSearch) {
      return;
    }

    setReload((state) => ({ ...state, list: true }));
  }, [search]);

  // 取得列表資料
  useAbortedEffect(
    (signal) => {
      if (!reload.list) {
        return;
      }

      dispatch(setProgress(true));

      let params = {
        ...search,
        page: search.isTotal ? -1 : search.page,
        size: search.isTotal ? -1 : search.size,
      };
      delete params.isTotal;

      setPageSetting({ params, page: "/inventory/gross" });

      InventoryAPI.getGross({ data: params, options: { signal } }).then(
        (result) => {
          let { Response, data, total, message } = result;

          if (Response === 1 && data) {
            if (search.isTotal) {
              setTbRows(total);
            }

            setRawData([...data]);
            setTbData([...data]);
            setTbTotal(total);
            setReload((state) => ({
              ...state,
              list: false,
            }));
            dispatch(setProgress(false));
          } else {
            dispatch(
              setToast({
                severity: "error",
                summary: message,
                detail: "",
              })
            );
          }

          dispatch(setProgress(false));
        }
      );
    },
    [reload]
  );

  // 設定列表欄位
  useEffect(() => {
    let entries = Object.values(grossExportColumns ?? {});
    if (entries.length === 0) return;

    // 重整排序
    entries.sort(function (pre, next) {
      return pre.order - next.order;
    });

    // 整理表格欄位
    let tempColumns = createColumns({
      type: "list",
      saveType: "no",
      activeTemplate: entries,
      defaultTemplate: grossExportColumns,
      frozenSetting: {
        frozen: true,
        align: "left",
        column: ["sku", "asin", "fnsku", "warehouse_sku", "fba_numbers"],
      },
      optionData: optionData,
      tbRows,
    });
    setTbColumns(tempColumns);
  }, [tbData, tbRows]);

  return (
    <>
      <ModuleBreadCrumb className="mb-3 border-none" crumbItem={crumbItem} />

      <div className="grid mr-0">
        <div className="col-12 sm:col-6 md:col-3 lg:col-2 pr-0">
          <InputText
            name="keywords"
            className="w-full"
            value={filterData.keywords}
            onChange={(e) => changeFilter(e)}
            placeholder="Keyword"
          />
        </div>
        {/* <div className="col-12 sm:col-6 md:col-3 lg:col-2 pr-0">
          <Dropdown
            name="supplier"
            className="w-full"
            value={filterData.supplier}
            onChange={(e) => changeFilter(e)}
            options={optionData.suppliers}
            optionLabel="Code"
            optionValue="Id"
            placeholder="Select Supplier"
            emptyMessage={system_dropdown.empty}
            showClear
          />
        </div>
        <div className="col-12 sm:col-6 md:col-3 lg:col-2 pr-0">
          <Dropdown
            name="source"
            className="w-full"
            value={filterData.source}
            onChange={(e) => changeFilter(e)}
            options={optionData.sources}
            optionLabel="Name"
            optionValue="Id"
            placeholder="Select Source"
            emptyMessage={system_dropdown.empty}
            showClear
          />
        </div> */}
        <div className="flex col-12 sm:col-6 md:col-9 lg:col-10 pr-0">
          <Button
            className="p-button-secondary p-button-icon-only px-4 ml-auto sm:ml-0"
            icon="pi pi-search"
            onClick={() => {
              setSearch((state) => ({ ...state, ...filterData, page: 1 }));
              setTbFirstIndex(0);
            }}
          />
        </div>
      </div>

      <div className="mt-3">
        <DataTable
          value={tbData}
          className={classNames("size-sm", {
            "table-empty": tbData.length === 0,
          })}
          size="normal"
          paginator
          paginatorTemplate={ModuleTablePaginator}
          currentPageReportTemplate="Total {totalRecords} items"
          // lazy
          // first={tbFirstIndex}
          // totalRecords={tbTotal}
          // onPage={onPage}
          rows={tbRows}
          scrollable
          scrollHeight="calc(100vh - 20rem)"
          scrollDirection="both"
          emptyMessage={system_table.empty}
        >
          {tbColumns?.map((item, index) => (
            <Column
              key={`${item.field}_${index}`}
              {...item}
              className={classNames(item.className, {
                "surface-100": index % 2 === 1,
              })}
              header={
                <div className="flex align-items-center">
                  {typeof item.header === "function"
                    ? item.header()
                    : item.header}
                  <ModuleTableSort
                    field={item.field}
                    sortField={tbSortField}
                    setSortField={setTbSortField}
                    rawTbData={rawData}
                    tbData={tbData}
                    setTbData={setTbData}
                    sortAction={({ data, rawData, field, setData }) =>
                      formatTbSort({
                        data,
                        rawData,
                        field,
                        setData,
                      })
                    }
                  />
                </div>
              }
            />
          ))}
        </DataTable>

        {tbData.length === 0 && <ModuleTableEmpty />}
      </div>
    </>
  );
}
