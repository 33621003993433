import { useMemo } from "react";

export default function ModuleTableSort({
  field,
  sortField,
  setSortField,
  rawTbData,
  tbData,
  setTbData,
  sortAction,
}) {
  const nowIcon = useMemo(
    () => sortField.find((item) => item.field === field)?.icon ?? null,
    [sortField]
  );

  const sortChange = (icon) => {
    let tempField = [...sortField],
      matchIdx = sortField.findIndex((item) => item.field === field);
    // // 單個排序
    // if (icon === null) {
    //   tempField = [
    //     {
    //       field,
    //       icon: null,
    //     },
    //   ];
    // } else {
    //   tempField = [
    //     {
    //       field,
    //       icon,
    //     },
    //   ];
    // }
    // 多個排序
    if (icon === null) {
      tempField[matchIdx].icon = null;
    } else if (matchIdx === -1) {
      tempField.push({
        field,
        icon,
      });
    } else {
      tempField[matchIdx].icon = icon;
    }

    setSortField(tempField);
    sortAction({
      data: tbData,
      rawData: rawTbData,
      field: tempField,
      setData: setTbData,
    });

    // let tempData = [...tbData];
    // tempData.sort(function (pre, next) {
    //   let flag = 0;
    //   tempField.forEach((item) => {
    //     let start = item.icon === 1 ? pre : next,
    //       end = item.icon === 1 ? next : pre;
    //     if (item.type === "string") {
    //       flag = start[item.field].localeCompare(end[item.field]);
    //     } else if (item.type === "number") {
    //       let limit = item.icon === 1 ? 99999999 : -99999999;
    //       flag = (start[item.field] ?? limit) - (end[item.field] ?? limit);
    //       if (isNaN(flag)) flag = -1;
    //     }
    //   });
    //   return flag;
    // });
    // if (tempField.length === 0) tempData = rawTbData;
    // setTbData(tempData);
  };

  return (
    <>
      <div className="ml-2">
        {nowIcon === null ? (
          <i
            className="pi pi-sort-alt hover:text-blue-200 cursor-pointer"
            onClick={() => sortChange("ASC")}
          ></i>
        ) : nowIcon === "ASC" ? (
          <i
            className="pi pi-sort-amount-up-alt text-blue-400 hover:text-blue-200 cursor-pointer"
            onClick={() => sortChange("DESC")}
          ></i>
        ) : nowIcon === "DESC" ? (
          <i
            className="pi pi-sort-amount-down text-blue-400 hover:text-blue-200 cursor-pointer"
            onClick={() => sortChange(null)}
          ></i>
        ) : null}
      </div>
    </>
  );
}

export const sortParams = ({ params, sortField }) => {
  let tempSortField = sortField.filter((item) => item.icon);
  if (tempSortField.length > 0) {
    params.sortFields = tempSortField.map((item) => item.field).join(",");
    params.sortOrders = tempSortField
      .map((item) => item.icon.toLowerCase())
      .join(",");
  } else {
    delete params.sortFields;
    delete params.sortOrders;
  }

  return params;
};

export const formatTbSort = ({
  data,
  rawData,
  field,
  setData,
  isForm = false,
  reset,
  trigger,
}) => {
  let tempFidld = field.filter((item) => item.icon);

  if (tempFidld.length > 0) {
    data.sort(function (pre, next) {
      let result = tempFidld.reduce((preResult, curField) => {
        let isNum =
          typeof pre[curField.field] === "number" &&
          typeof next[curField.field] === "number";

        if (curField.icon === "DESC") {
          preResult =
            preResult ||
            (isNum
              ? next[curField.field] - pre[curField.field]
              : next[curField.field].localeCompare(pre[curField.field]));
        } else if (curField.icon === "ASC")
          preResult =
            preResult ||
            (isNum
              ? pre[curField.field] - next[curField.field]
              : pre[curField.field].localeCompare(next[curField.field]));

        return preResult;
      }, 0);

      return result;
    });
  } else {
    let match_obj = data.reduce(
      (pre, cur) => ({
        ...pre,
        [cur.serial_idx]: cur,
      }),
      {}
    );
    data = rawData.map((item) => match_obj[item.serial_idx]);
  }

  setData(data);
  if (isForm && reset) {
    reset({ data });
    trigger();
  }
};
