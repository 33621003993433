import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setProgress, setToast } from "../../../redux/slice/system";
import { CommonAPI } from "../../../axios/api";

import ModuleBreadCrumb from "../../../components/module/breadCrumb";
import ModuleTableEmpty from "../../../components/module/table/empty";
import ModuleTableSort, {
  sortParams,
} from "../../../components/module/table/sort";
import CommonWarehousePopEdit from "./popEdit";
import { useAbortedEffect } from "../../../components/hooks";
import { carrierExportColumns } from "../../../service/columns/common/carrier";
import { option_open, option_shipping_method } from "../../../service/option";
import { system_table, system_dropdown } from "../../../service/system";
import {
  getPageSetting,
  setPageSetting,
  formatOptions,
} from "../../../service/common";
import {
  createColumns,
  createSchema,
  ModuleTablePaginator,
} from "../../../service/table";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";

export default function CommonCarrier(props) {
  const dispatch = useDispatch();
  const crumbItem = [{ label: "Common" }, { label: "Carrier" }];
  const [reload, setReload] = useState({ list: false });
  // 列表資料
  const [tbData, setTbData] = useState([]);
  const [tbTotal, setTbTotal] = useState(0);
  const [tbFirstIndex, setTbFirstIndex] = useState(0);
  const [tbRows, setTbRows] = useState(system_table.size);
  const [tbColumns, setTbColumns] = useState([]);
  const [tbSortField, setTbSortField] = useState([]);
  // 篩選條件
  const defaultFilter = {
    page: 1,
    size: system_table.size,
    isTotal: false,
    keywords: "",
  };
  const [filterData, setFilterData] = useState(defaultFilter);
  const [search, setSearch] = useState({});
  // 彈出視窗
  const [isPop, setIsPop] = useState({
    edit: false, // 編輯
  });
  const [popOption, setPopOption] = useState({
    type: "", // 開啟的pop
  });
  const [optionData, setOptionData] = useState({});

  useEffect(() => {
    let temp = formatOptions({
      open: option_open,
      shipping_method: option_shipping_method,
    });
    setOptionData(temp);
  }, []);

  // 開啟 彈出視窗
  const openPop = (options) => {
    setPopOption(options);
    setIsPop({
      ...isPop,
      [options.type]: true,
    });
  };

  // 關閉 彈出視窗
  const closePop = (options) => {
    setIsPop((state) => ({
      ...state,
      [options.type ?? popOption.type]: false,
    }));

    // if (options?.reload) {
    //   setReload((state) => ({
    //     ...state,
    //     list: true,
    //   }));
    // }
  };

  // 表格頁數切換觸發
  const onPage = (e) => {
    const startIndex = e.first,
      rows = e.rows;

    // 同一頁面，不刷新
    if (startIndex === tbFirstIndex && rows === tbRows) {
      return;
    }

    setTbFirstIndex(startIndex);
    setTbRows(e.rows);

    let temp = {
      ...search,
      page: Math.floor(startIndex / e.rows) + 1,
      size: rows,
      isTotal: rows === tbTotal,
    };

    setFilterData(temp);
    setSearch(temp);
  };

  // 取 該頁搜尋參數
  useEffect(() => {
    let result = getPageSetting(["/common/carrier"]);

    setTbFirstIndex(
      result && result.params.page >= 0
        ? (result.params.page - 1) * result.params.size
        : 0
    );
    if (result) {
      setTbRows(result.params.size);
      setFilterData((state) => ({ ...state, ...result.params }));
    }
    setSearch(result ? result.params : filterData);
  }, []);

  // 更新篩選條件
  const changeFilter = ({ target }) => {
    let { name, value } = target;

    setFilterData((state) => ({
      ...state,
      [name]: value,
    }));
  };

  // 判斷是否搜尋
  useEffect(() => {
    let isSearch = Object.keys(search).length > 0;
    if (!isSearch) {
      return;
    }

    setReload((state) => ({ ...state, list: true }));
  }, [search]);

  // 取得列表資料
  useAbortedEffect(
    (signal) => {
      if (!reload.list) {
        return;
      }

      dispatch(setProgress(true));

      let params = {
        ...search,
        page: search.isTotal ? -1 : search.page,
        size: search.isTotal ? -1 : search.size,
      };

      params = sortParams({ params, sortField: tbSortField });

      setPageSetting({ params, page: "/common/warehouse" });

      CommonAPI.getCarrier({ data: params, options: { signal } }).then(
        (result) => {
          let { Response, data, total, message } = result;

          if (Response === 1 && data) {
            if (search.isTotal) {
              setTbRows(total);
            }

            setTbData(data);
            setTbTotal(total);
            setReload((state) => ({
              ...state,
              list: false,
            }));
          } else {
            dispatch(
              setToast({
                severity: "error",
                summary: message,
                detail: "",
              })
            );
          }

          dispatch(setProgress(false));
        }
      );
    },
    [reload]
  );

  // 設定列表欄位
  useEffect(() => {
    let entries = Object.values(carrierExportColumns ?? {});
    if (entries.length === 0) return;

    // 重整排序
    entries.sort(function (pre, next) {
      return pre.order - next.order;
    });

    // 整理表格欄位
    let tempColumns = createColumns({
      type: "list",
      saveType: "no",
      activeTemplate: entries,
      defaultTemplate: carrierExportColumns,
      optionData: optionData,
      tbRows,
    });
    // 欄位-編輯
    tempColumns = tempColumns.concat([
      {
        field: "status",
        header: "Status",
        className: "justify-content-center",
        style: {
          width: 80,
          minWidth: 80,
        },
        body: (data, { rowIndex }) => (
          <span className={classNames({ "text-red-500": data.status === 0 })}>
            {optionData?.open_obj?.[data.status]?.showLabel}
          </span>
        ),
      },
      {
        field: "id",
        header: "Edit",
        className: "justify-content-center",
        style: {
          width: 40,
          minWidth: 40,
          boxShadow: "-3px 1px 5px rgb(0 0 0 / 5%)",
        },
        frozen: true,
        alignFrozen: "right",
        body: (data, { rowIndex }) => (
          <>
            <Button
              type="button"
              icon="pi pi-pencil"
              className="p-button-primary p-button-sm p-button-icon-only p-button-outlined w-auto px-2 py-0"
              onClick={() => openPop({ type: "edit", data: data })}
            />
          </>
        ),
      },
    ]);
    setTbColumns(tempColumns);
  }, [tbData, tbRows]);

  return (
    <>
      <ModuleBreadCrumb className="mb-3 border-none" crumbItem={crumbItem} />

      <div className="grid mr-0">
        <div className="col-12 sm:col-6 md:col-3 lg:col-2 pr-0">
          <InputText
            name="keywords"
            className="w-full"
            value={filterData.keywords}
            onChange={(e) => changeFilter(e)}
            placeholder="keywords"
          />
        </div>
        <div className="flex col-6 sm:col-3 md:col-3 lg:col-2 pr-0">
          <Button
            className="p-button-secondary p-button-icon-only px-4 ml-0"
            icon="pi pi-search"
            onClick={() => {
              setSearch((state) => ({ ...state, ...filterData, page: 1 }));
              setTbFirstIndex(0);
            }}
          />
        </div>
        <div className="flex col-6 sm:col-3 md:col-6 lg:col-8 pr-0">
          <Button
            type="button"
            label="Add"
            className="p-button-primary ml-auto"
            onClick={() => openPop({ type: "edit", data: null })}
          />
        </div>
      </div>

      <div className="mt-3">
        <DataTable
          value={tbData}
          className={classNames("size-sm", {
            "table-empty": tbData.length === 0,
          })}
          size="normal"
          paginator
          paginatorTemplate={ModuleTablePaginator}
          currentPageReportTemplate="Total {totalRecords} items"
          lazy
          first={tbFirstIndex}
          totalRecords={tbTotal}
          onPage={onPage}
          rows={tbRows}
          scrollable
          scrollDirection="both"
          emptyMessage={system_table.empty}
        >
          {tbColumns?.map((item, index) => (
            <Column
              key={`${item.field}_${index}`}
              {...item}
              {...(item.header !== "Edit"
                ? {
                    header: (
                      <div className="flex align-items-center">
                        {typeof item.header === "function"
                          ? item.header()
                          : item.header}
                        {item.header && (
                          <ModuleTableSort
                            field={item.field}
                            sortField={tbSortField}
                            setSortField={setTbSortField}
                            rawTbData={[]}
                            tbData={tbData}
                            setTbData={setTbData}
                            sortAction={() =>
                              setReload((state) => ({ list: true }))
                            }
                          />
                        )}
                      </div>
                    ),
                  }
                : {})}
            />
          ))}
        </DataTable>

        {tbData.length === 0 && <ModuleTableEmpty />}
      </div>

      <Dialog
        header={
          <h3 className="my-0">{popOption.type === "edit" ? "Edit" : ""}</h3>
        }
        headerClassName="border-300"
        contentClassName="p-0"
        visible={isPop.edit}
        className="max-w-30rem w-11"
        onHide={() => closePop({ type: popOption.type })}
        // closable={false}
      >
        {isPop.edit ? (
          <CommonWarehousePopEdit
            closePrePop={closePop}
            prePopOption={popOption}
            setPreReload={setReload}
          />
        ) : null}
      </Dialog>
    </>
  );
}
