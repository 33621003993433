export const option_function_type = {
  unshippedOrder: 1,
  wayfairOrder: 2,
  dfOrder: 3,
  newOrder: 4,
  newOrders: 5,
  overview: 6,
  wayfairFinancial: 7,
  shippedFee: 8,
  tracking: 9,
  cjFile: 10,
  cjFedEx: 11,
};

export const option_daily_import_type = [
  { label: "Unshipped Orders", value: "unshippedOrder" },
  { label: "New Orders", value: "newOrder" },
  { label: "Wayfair Orders", value: "wayfairOrder" },
  { label: "DF Orders", value: "dfOrder" },
];

export const option_daily_overview_type = [
  { label: "Overview", value: "overview" },
  { label: "Wayfair Financial", value: "wayfairFinancial" },
  { label: "Shipped Fee", value: "shippedFee" },
  { label: "Tracking", value: "tracking" },
];

export const option_daily_cj_type = [
  { label: "CJ File", value: "cjFile" },
  { label: "CJ FedEx", value: "cjFedEx" },
];

export const option_open = [
  { label: "啟用", value: 1 },
  { label: "停用", value: 0 },
];

export const option_pallet_type = [
  { label: "Parent", value: 1 },
  { label: "Child", value: 2 },
];

export const option_confirm_type = [
  { label: "New", value: 1 },
  { label: "Reserved", value: 2 },
  { label: "Notified Amazon", value: 3 },
  { label: "Waiting BOL", value: 10 },
  { label: "Sent BOL", value: 11 },
  { label: "Archive POD", value: 12 },
  { label: "Waiting Shipping Label", value: 20 },
  { label: "Unshipped Parcel", value: 21 },
  { label: "Shipped Parcel", value: 22 },
  { label: "ASN", value: 40 },
];

export const option_accept_reject = [
  { label: "Reject", value: 1 },
  { label: "Accept", value: 0 },
];

export const option_shipping_method = [
  { label: "Ground", value: 1 },
  { label: "Freight", value: 2 },
];

export const option_shipping_unit_freight = [
  {
    label: "0 Pallet",
    value: 0,
  },
  {
    label: "1 Pallet",
    value: 1,
  },
  {
    label: "2 Pallet",
    value: 2,
  },
  {
    label: "3 Pallet",
    value: 3,
  },
  {
    label: "4 Pallet",
    value: 4,
  },
  {
    label: "5 Pallet",
    value: 5,
  },
  {
    label: "6 Pallet",
    value: 6,
  },
  {
    label: "7 Pallet",
    value: 7,
  },
  {
    label: "8 Pallet",
    value: 8,
  },
  {
    label: "9 Pallet",
    value: 9,
  },
  {
    label: "10 Pallet",
    value: 10,
  },
  {
    label: "11 Pallet",
    value: 11,
  },
  {
    label: "12 Pallet",
    value: 12,
  },
  {
    label: "13 Pallet",
    value: 13,
  },
  {
    label: "14 Pallet",
    value: 14,
  },
  {
    label: "15 Pallet",
    value: 15,
  },
  {
    label: "16 Pallet",
    value: 16,
  },
  {
    label: "17 Pallet",
    value: 17,
  },
  {
    label: "18 Pallet",
    value: 18,
  },
  {
    label: "19 Pallet",
    value: 19,
  },
  {
    label: "20 Pallet",
    value: 20,
  },
  {
    label: "21 Pallet",
    value: 21,
  },
  {
    label: "22 Pallet",
    value: 22,
  },
  {
    label: "23 Pallet",
    value: 23,
  },
  {
    label: "24 Pallet",
    value: 24,
  },
  {
    label: "25 Pallet",
    value: 25,
  },
  {
    label: "26 Pallet",
    value: 26,
  },
  {
    label: "27 Pallet",
    value: 27,
  },
  {
    label: "28 Pallet",
    value: 28,
  },
  {
    label: "29 Pallet",
    value: 29,
  },
  {
    label: "30 Pallet",
    value: 30,
  },
  {
    label: "31 Pallet",
    value: 31,
  },
  {
    label: "32 Pallet",
    value: 32,
  },
  {
    label: "33 Pallet",
    value: 33,
  },
  {
    label: "34 Pallet",
    value: 34,
  },
  {
    label: "35 Pallet",
    value: 35,
  },
  {
    label: "36 Pallet",
    value: 36,
  },
  {
    label: "37 Pallet",
    value: 37,
  },
  {
    label: "38 Pallet",
    value: 38,
  },
  {
    label: "39 Pallet",
    value: 39,
  },
  {
    label: "40 Pallet",
    value: 40,
  },
  {
    label: "41 Pallet",
    value: 41,
  },
  {
    label: "42 Pallet",
    value: 42,
  },
  {
    label: "43 Pallet",
    value: 43,
  },
  {
    label: "44 Pallet",
    value: 44,
  },
  {
    label: "45 Pallet",
    value: 45,
  },
  {
    label: "46 Pallet",
    value: 46,
  },
  {
    label: "47 Pallet",
    value: 47,
  },
  {
    label: "48 Pallet",
    value: 48,
  },
  {
    label: "49 Pallet",
    value: 49,
  },
  {
    label: "50 Pallet",
    value: 50,
  },
  {
    label: "51 Pallet",
    value: 51,
  },
  {
    label: "52 Pallet",
    value: 52,
  },
  {
    label: "53 Pallet",
    value: 53,
  },
  {
    label: "54 Pallet",
    value: 54,
  },
  {
    label: "55 Pallet",
    value: 55,
  },
  {
    label: "56 Pallet",
    value: 56,
  },
  {
    label: "57 Pallet",
    value: 57,
  },
  {
    label: "58 Pallet",
    value: 58,
  },
  {
    label: "59 Pallet",
    value: 59,
  },
  {
    label: "60 Pallet",
    value: 60,
  },
  {
    label: "61 Pallet",
    value: 61,
  },
  {
    label: "62 Pallet",
    value: 62,
  },
  {
    label: "63 Pallet",
    value: 63,
  },
  {
    label: "64 Pallet",
    value: 64,
  },
  {
    label: "65 Pallet",
    value: 65,
  },
  {
    label: "66 Pallet",
    value: 66,
  },
  {
    label: "67 Pallet",
    value: 67,
  },
  {
    label: "68 Pallet",
    value: 68,
  },
  {
    label: "69 Pallet",
    value: 69,
  },
  {
    label: "70 Pallet",
    value: 70,
  },
  {
    label: "71 Pallet",
    value: 71,
  },
  {
    label: "72 Pallet",
    value: 72,
  },
  {
    label: "73 Pallet",
    value: 73,
  },
  {
    label: "74 Pallet",
    value: 74,
  },
  {
    label: "75 Pallet",
    value: 75,
  },
  {
    label: "76 Pallet",
    value: 76,
  },
  {
    label: "77 Pallet",
    value: 77,
  },
  {
    label: "78 Pallet",
    value: 78,
  },
  {
    label: "79 Pallet",
    value: 79,
  },
  {
    label: "80 Pallet",
    value: 80,
  },
  {
    label: "81 Pallet",
    value: 81,
  },
  {
    label: "82 Pallet",
    value: 82,
  },
  {
    label: "83 Pallet",
    value: 83,
  },
  {
    label: "84 Pallet",
    value: 84,
  },
  {
    label: "85 Pallet",
    value: 85,
  },
  {
    label: "86 Pallet",
    value: 86,
  },
  {
    label: "87 Pallet",
    value: 87,
  },
  {
    label: "88 Pallet",
    value: 88,
  },
  {
    label: "89 Pallet",
    value: 89,
  },
  {
    label: "90 Pallet",
    value: 90,
  },
  {
    label: "91 Pallet",
    value: 91,
  },
  {
    label: "92 Pallet",
    value: 92,
  },
  {
    label: "93 Pallet",
    value: 93,
  },
  {
    label: "94 Pallet",
    value: 94,
  },
  {
    label: "95 Pallet",
    value: 95,
  },
  {
    label: "96 Pallet",
    value: 96,
  },
  {
    label: "97 Pallet",
    value: 97,
  },
  {
    label: "98 Pallet",
    value: 98,
  },
  {
    label: "99 Pallet",
    value: 99,
  },
  {
    label: "100 Pallet",
    value: 100,
  },
];

export const option_shipping_unit_ground = [
  {
    label: "0 Carton",
    value: 0,
  },
  {
    label: "1 Carton",
    value: 1,
  },
  {
    label: "2 Carton",
    value: 2,
  },
  {
    label: "3 Carton",
    value: 3,
  },
  {
    label: "4 Carton",
    value: 4,
  },
  {
    label: "5 Carton",
    value: 5,
  },
  {
    label: "6 Carton",
    value: 6,
  },
  {
    label: "7 Carton",
    value: 7,
  },
  {
    label: "8 Carton",
    value: 8,
  },
  {
    label: "9 Carton",
    value: 9,
  },
  {
    label: "10 Carton",
    value: 10,
  },
  {
    label: "11 Carton",
    value: 11,
  },
  {
    label: "12 Carton",
    value: 12,
  },
  {
    label: "13 Carton",
    value: 13,
  },
  {
    label: "14 Carton",
    value: 14,
  },
  {
    label: "15 Carton",
    value: 15,
  },
  {
    label: "16 Carton",
    value: 16,
  },
  {
    label: "17 Carton",
    value: 17,
  },
  {
    label: "18 Carton",
    value: 18,
  },
  {
    label: "19 Carton",
    value: 19,
  },
  {
    label: "20 Carton",
    value: 20,
  },
  {
    label: "21 Carton",
    value: 21,
  },
  {
    label: "22 Carton",
    value: 22,
  },
  {
    label: "23 Carton",
    value: 23,
  },
  {
    label: "24 Carton",
    value: 24,
  },
  {
    label: "25 Carton",
    value: 25,
  },
  {
    label: "26 Carton",
    value: 26,
  },
  {
    label: "27 Carton",
    value: 27,
  },
  {
    label: "28 Carton",
    value: 28,
  },
  {
    label: "29 Carton",
    value: 29,
  },
  {
    label: "30 Carton",
    value: 30,
  },
  {
    label: "31 Carton",
    value: 31,
  },
  {
    label: "32 Carton",
    value: 32,
  },
  {
    label: "33 Carton",
    value: 33,
  },
  {
    label: "34 Carton",
    value: 34,
  },
  {
    label: "35 Carton",
    value: 35,
  },
  {
    label: "36 Carton",
    value: 36,
  },
  {
    label: "37 Carton",
    value: 37,
  },
  {
    label: "38 Carton",
    value: 38,
  },
  {
    label: "39 Carton",
    value: 39,
  },
  {
    label: "40 Carton",
    value: 40,
  },
  {
    label: "41 Carton",
    value: 41,
  },
  {
    label: "42 Carton",
    value: 42,
  },
  {
    label: "43 Carton",
    value: 43,
  },
  {
    label: "44 Carton",
    value: 44,
  },
  {
    label: "45 Carton",
    value: 45,
  },
  {
    label: "46 Carton",
    value: 46,
  },
  {
    label: "47 Carton",
    value: 47,
  },
  {
    label: "48 Carton",
    value: 48,
  },
  {
    label: "49 Carton",
    value: 49,
  },
  {
    label: "50 Carton",
    value: 50,
  },
  {
    label: "51 Carton",
    value: 51,
  },
  {
    label: "52 Carton",
    value: 52,
  },
  {
    label: "53 Carton",
    value: 53,
  },
  {
    label: "54 Carton",
    value: 54,
  },
  {
    label: "55 Carton",
    value: 55,
  },
  {
    label: "56 Carton",
    value: 56,
  },
  {
    label: "57 Carton",
    value: 57,
  },
  {
    label: "58 Carton",
    value: 58,
  },
  {
    label: "59 Carton",
    value: 59,
  },
  {
    label: "60 Carton",
    value: 60,
  },
  {
    label: "61 Carton",
    value: 61,
  },
  {
    label: "62 Carton",
    value: 62,
  },
  {
    label: "63 Carton",
    value: 63,
  },
  {
    label: "64 Carton",
    value: 64,
  },
  {
    label: "65 Carton",
    value: 65,
  },
  {
    label: "66 Carton",
    value: 66,
  },
  {
    label: "67 Carton",
    value: 67,
  },
  {
    label: "68 Carton",
    value: 68,
  },
  {
    label: "69 Carton",
    value: 69,
  },
  {
    label: "70 Carton",
    value: 70,
  },
  {
    label: "71 Carton",
    value: 71,
  },
  {
    label: "72 Carton",
    value: 72,
  },
  {
    label: "73 Carton",
    value: 73,
  },
  {
    label: "74 Carton",
    value: 74,
  },
  {
    label: "75 Carton",
    value: 75,
  },
  {
    label: "76 Carton",
    value: 76,
  },
  {
    label: "77 Carton",
    value: 77,
  },
  {
    label: "78 Carton",
    value: 78,
  },
  {
    label: "79 Carton",
    value: 79,
  },
  {
    label: "80 Carton",
    value: 80,
  },
  {
    label: "81 Carton",
    value: 81,
  },
  {
    label: "82 Carton",
    value: 82,
  },
  {
    label: "83 Carton",
    value: 83,
  },
  {
    label: "84 Carton",
    value: 84,
  },
  {
    label: "85 Carton",
    value: 85,
  },
  {
    label: "86 Carton",
    value: 86,
  },
  {
    label: "87 Carton",
    value: 87,
  },
  {
    label: "88 Carton",
    value: 88,
  },
  {
    label: "89 Carton",
    value: 89,
  },
  {
    label: "90 Carton",
    value: 90,
  },
  {
    label: "91 Carton",
    value: 91,
  },
  {
    label: "92 Carton",
    value: 92,
  },
  {
    label: "93 Carton",
    value: 93,
  },
  {
    label: "94 Carton",
    value: 94,
  },
  {
    label: "95 Carton",
    value: 95,
  },
  {
    label: "96 Carton",
    value: 96,
  },
  {
    label: "97 Carton",
    value: 97,
  },
  {
    label: "98 Carton",
    value: 98,
  },
  {
    label: "99 Carton",
    value: 99,
  },
  {
    label: "100 Carton",
    value: 100,
  },
];

export const option_platform = [
  { label: "FBM", value: 1 },
  { label: "WF", value: 2 },
  { label: "DF", value: 3 },
  { label: "WM", value: 4 },
];

export const option_shipping_status = [
  { label: "未出貨", value: 0 },
  { label: "已出貨", value: 1 },
  { label: "未送達", value: 2 },
  { label: "已送達", value: 3 },
];

export const option_ship_service_level = [
  { label: "Standard", value: "Standard" },
];

export const option_carriers = [
  { label: "UPS", value: 1 },
  { label: "FedEx", value: 2 },
  { label: "USPS", value: 3 },
  { label: "CEVA", value: 4 },
];

export const option_ship_state = [
  {
    id: 1,
    code: "AD",
    name: "Andorra",
    zipName: "20 (Andorra)",
    zipcode: 20,
  },
  {
    id: 2,
    code: "AE",
    name: "United Arab Emirates",
    zipName: "784 (United Arab Emirates)",
    zipcode: 784,
  },
  {
    id: 3,
    code: "AF",
    name: "Afghanistan",
    zipName: "4 (Afghanistan)",
    zipcode: 4,
  },
  {
    id: 4,
    code: "AG",
    name: "Antigua and Barbuda",
    zipName: "28 (Antigua and Barbuda)",
    zipcode: 28,
  },
  {
    id: 5,
    code: "AI",
    name: "Anguilla",
    zipName: "660 (Anguilla)",
    zipcode: 660,
  },
  {
    id: 6,
    code: "AL",
    name: "Albania",
    zipName: "8 (Albania)",
    zipcode: 8,
  },
  {
    id: 7,
    code: "AM",
    name: "Armenia",
    zipName: "51 (Armenia)",
    zipcode: 51,
  },
  {
    id: 8,
    code: "AO",
    name: "Angola",
    zipName: "24 (Angola)",
    zipcode: 24,
  },
  {
    id: 9,
    code: "AQ",
    name: "Antarctica",
    zipName: "10 (Antarctica)",
    zipcode: 10,
  },
  {
    id: 10,
    code: "AR",
    name: "Argentina",
    zipName: "32 (Argentina)",
    zipcode: 32,
  },
  {
    id: 11,
    code: "AS",
    name: "American Samoa",
    zipName: "16 (American Samoa)",
    zipcode: 16,
  },
  {
    id: 12,
    code: "AT",
    name: "Austria",
    zipName: "40 (Austria)",
    zipcode: 40,
  },
  {
    id: 13,
    code: "AU",
    name: "Australia",
    zipName: "36 (Australia)",
    zipcode: 36,
  },
  {
    id: 14,
    code: "AW",
    name: "Aruba",
    zipName: "533 (Aruba)",
    zipcode: 533,
  },
  {
    id: 15,
    code: "AX",
    name: "Åland Islands",
    zipName: "248 (Åland Islands)",
    zipcode: 248,
  },
  {
    id: 16,
    code: "AZ",
    name: "Azerbaijan",
    zipName: "31 (Azerbaijan)",
    zipcode: 31,
  },
  {
    id: 17,
    code: "BA",
    name: "Bosnia and Herzegovina",
    zipName: "70 (Bosnia and Herzegovina)",
    zipcode: 70,
  },
  {
    id: 18,
    code: "BB",
    name: "Barbados",
    zipName: "52 (Barbados)",
    zipcode: 52,
  },
  {
    id: 19,
    code: "BD",
    name: "Bangladesh",
    zipName: "50 (Bangladesh)",
    zipcode: 50,
  },
  {
    id: 20,
    code: "BE",
    name: "Belgium",
    zipName: "56 (Belgium)",
    zipcode: 56,
  },
  {
    id: 21,
    code: "BF",
    name: "Burkina Faso",
    zipName: "854 (Burkina Faso)",
    zipcode: 854,
  },
  {
    id: 22,
    code: "BG",
    name: "Bulgaria",
    zipName: "100 (Bulgaria)",
    zipcode: 100,
  },
  {
    id: 23,
    code: "BH",
    name: "Bahrain",
    zipName: "48 (Bahrain)",
    zipcode: 48,
  },
  {
    id: 24,
    code: "BI",
    name: "Burundi",
    zipName: "108 (Burundi)",
    zipcode: 108,
  },
  {
    id: 25,
    code: "BJ",
    name: "Benin",
    zipName: "204 (Benin)",
    zipcode: 204,
  },
  {
    id: 26,
    code: "BL",
    name: "Saint Barthélemy",
    zipName: "652 (Saint Barthélemy)",
    zipcode: 652,
  },
  {
    id: 27,
    code: "BM",
    name: "Bermuda",
    zipName: "60 (Bermuda)",
    zipcode: 60,
  },
  {
    id: 28,
    code: "BN",
    name: "Brunei Darussalam",
    zipName: "96 (Brunei Darussalam)",
    zipcode: 96,
  },
  {
    id: 29,
    code: "BO",
    name: "Bolivia",
    zipName: "68 (Bolivia)",
    zipcode: 68,
  },
  {
    id: 30,
    code: "BQ",
    name: "Bonaire",
    zipName: "535 (Bonaire)",
    zipcode: 535,
  },
  {
    id: 31,
    code: "BR",
    name: "Brazil",
    zipName: "76 (Brazil)",
    zipcode: 76,
  },
  {
    id: 32,
    code: "BS",
    name: "Bahamas",
    zipName: "44 (Bahamas)",
    zipcode: 44,
  },
  {
    id: 33,
    code: "BT",
    name: "Bhutan",
    zipName: "64 (Bhutan)",
    zipcode: 64,
  },
  {
    id: 34,
    code: "BV",
    name: "Bouvet Island",
    zipName: "74 (Bouvet Island)",
    zipcode: 74,
  },
  {
    id: 35,
    code: "BW",
    name: "Botswana",
    zipName: "72 (Botswana)",
    zipcode: 72,
  },
  {
    id: 36,
    code: "BY",
    name: "Belarus",
    zipName: "112 (Belarus)",
    zipcode: 112,
  },
  {
    id: 37,
    code: "BZ",
    name: "Belize",
    zipName: "84 (Belize)",
    zipcode: 84,
  },
  {
    id: 38,
    code: "CA",
    name: "Canada",
    zipName: "124 (Canada)",
    zipcode: 124,
  },
  {
    id: 39,
    code: "CC",
    name: "Cocos (Keeling) Islands",
    zipName: "166 (Cocos (Keeling) Islands)",
    zipcode: 166,
  },
  {
    id: 40,
    code: "CD",
    name: "Congo",
    zipName: "180 (Congo)",
    zipcode: 180,
  },
  {
    id: 41,
    code: "CF",
    name: "Central African Republic",
    zipName: "140 (Central African Republic)",
    zipcode: 140,
  },
  {
    id: 42,
    code: "CG",
    name: "Congo",
    zipName: "178 (Congo)",
    zipcode: 178,
  },
  {
    id: 43,
    code: "CH",
    name: "Switzerland",
    zipName: "756 (Switzerland)",
    zipcode: 756,
  },
  {
    id: 44,
    code: "CI",
    name: "Côte d'Ivoire",
    zipName: "384 (Côte d'Ivoire)",
    zipcode: 384,
  },
  {
    id: 45,
    code: "CK",
    name: "Cook Islands",
    zipName: "184 (Cook Islands)",
    zipcode: 184,
  },
  {
    id: 46,
    code: "CL",
    name: "Chile",
    zipName: "152 (Chile)",
    zipcode: 152,
  },
  {
    id: 47,
    code: "CM",
    name: "Cameroon",
    zipName: "120 (Cameroon)",
    zipcode: 120,
  },
  {
    id: 48,
    code: "CN",
    name: "China",
    zipName: "156 (China)",
    zipcode: 156,
  },
  {
    id: 49,
    code: "CO",
    name: "Colombia",
    zipName: "170 (Colombia)",
    zipcode: 170,
  },
  {
    id: 50,
    code: "CR",
    name: "Costa Rica",
    zipName: "188 (Costa Rica)",
    zipcode: 188,
  },
  {
    id: 51,
    code: "CU",
    name: "Cuba",
    zipName: "192 (Cuba)",
    zipcode: 192,
  },
  {
    id: 52,
    code: "CV",
    name: "Cabo Verde",
    zipName: "132 (Cabo Verde)",
    zipcode: 132,
  },
  {
    id: 53,
    code: "CW",
    name: "Curaçao",
    zipName: "531 (Curaçao)",
    zipcode: 531,
  },
  {
    id: 54,
    code: "CX",
    name: "Christmas Island",
    zipName: "162 (Christmas Island)",
    zipcode: 162,
  },
  {
    id: 55,
    code: "CY",
    name: "Cyprus",
    zipName: "196 (Cyprus)",
    zipcode: 196,
  },
  {
    id: 56,
    code: "CZ",
    name: "Czechia",
    zipName: "203 (Czechia)",
    zipcode: 203,
  },
  {
    id: 57,
    code: "DE",
    name: "Germany",
    zipName: "276 (Germany)",
    zipcode: 276,
  },
  {
    id: 58,
    code: "DJ",
    name: "Djibouti",
    zipName: "262 (Djibouti)",
    zipcode: 262,
  },
  {
    id: 59,
    code: "DK",
    name: "Denmark",
    zipName: "208 (Denmark)",
    zipcode: 208,
  },
  {
    id: 60,
    code: "DM",
    name: "Dominica",
    zipName: "212 (Dominica)",
    zipcode: 212,
  },
  {
    id: 61,
    code: "DO",
    name: "Dominican Republic",
    zipName: "214 (Dominican Republic)",
    zipcode: 214,
  },
  {
    id: 62,
    code: "DZ",
    name: "Algeria",
    zipName: "12 (Algeria)",
    zipcode: 12,
  },
  {
    id: 63,
    code: "EC",
    name: "Ecuador",
    zipName: "218 (Ecuador)",
    zipcode: 218,
  },
  {
    id: 64,
    code: "EE",
    name: "Estonia",
    zipName: "233 (Estonia)",
    zipcode: 233,
  },
  {
    id: 65,
    code: "EG",
    name: "Egypt",
    zipName: "818 (Egypt)",
    zipcode: 818,
  },
  {
    id: 66,
    code: "EH",
    name: "Western Sahara",
    zipName: "732 (Western Sahara)",
    zipcode: 732,
  },
  {
    id: 67,
    code: "ER",
    name: "Eritrea",
    zipName: "232 (Eritrea)",
    zipcode: 232,
  },
  {
    id: 68,
    code: "ES",
    name: "Spain",
    zipName: "724 (Spain)",
    zipcode: 724,
  },
  {
    id: 69,
    code: "ET",
    name: "Ethiopia",
    zipName: "231 (Ethiopia)",
    zipcode: 231,
  },
  {
    id: 70,
    code: "FI",
    name: "Finland",
    zipName: "246 (Finland)",
    zipcode: 246,
  },
  {
    id: 71,
    code: "FJ",
    name: "Fiji",
    zipName: "242 (Fiji)",
    zipcode: 242,
  },
  {
    id: 72,
    code: "FK",
    name: "Falkland Islands (Malvinas)",
    zipName: "238 (Falkland Islands (Malvinas))",
    zipcode: 238,
  },
  {
    id: 73,
    code: "FM",
    name: "Micronesia",
    zipName: "583 (Micronesia)",
    zipcode: 583,
  },
  {
    id: 74,
    code: "FO",
    name: "Faroe Islands",
    zipName: "234 (Faroe Islands)",
    zipcode: 234,
  },
  {
    id: 75,
    code: "FR",
    name: "France",
    zipName: "250 (France)",
    zipcode: 250,
  },
  {
    id: 76,
    code: "GA",
    name: "Gabon",
    zipName: "266 (Gabon)",
    zipcode: 266,
  },
  {
    id: 77,
    code: "GB",
    name: "United Kingdom",
    zipName: "826 (United Kingdom)",
    zipcode: 826,
  },
  {
    id: 78,
    code: "GD",
    name: "Grenada",
    zipName: "308 (Grenada)",
    zipcode: 308,
  },
  {
    id: 79,
    code: "GE",
    name: "Georgia",
    zipName: "268 (Georgia)",
    zipcode: 268,
  },
  {
    id: 80,
    code: "GF",
    name: "French Guiana",
    zipName: "254 (French Guiana)",
    zipcode: 254,
  },
  {
    id: 81,
    code: "GG",
    name: "Guernsey",
    zipName: "831 (Guernsey)",
    zipcode: 831,
  },
  {
    id: 82,
    code: "GH",
    name: "Ghana",
    zipName: "288 (Ghana)",
    zipcode: 288,
  },
  {
    id: 83,
    code: "GI",
    name: "Gibraltar",
    zipName: "292 (Gibraltar)",
    zipcode: 292,
  },
  {
    id: 84,
    code: "GL",
    name: "Greenland",
    zipName: "304 (Greenland)",
    zipcode: 304,
  },
  {
    id: 85,
    code: "GM",
    name: "Gambia",
    zipName: "270 (Gambia)",
    zipcode: 270,
  },
  {
    id: 86,
    code: "GN",
    name: "Guinea",
    zipName: "324 (Guinea)",
    zipcode: 324,
  },
  {
    id: 87,
    code: "GP",
    name: "Guadeloupe",
    zipName: "312 (Guadeloupe)",
    zipcode: 312,
  },
  {
    id: 88,
    code: "GQ",
    name: "Equatorial Guinea",
    zipName: "226 (Equatorial Guinea)",
    zipcode: 226,
  },
  {
    id: 89,
    code: "GR",
    name: "Greece",
    zipName: "300 (Greece)",
    zipcode: 300,
  },
  {
    id: 90,
    code: "GS",
    name: "South Georgia and the South Sandwich Islands",
    zipName: "239 (South Georgia and the South Sandwich Islands)",
    zipcode: 239,
  },
  {
    id: 91,
    code: "GT",
    name: "Guatemala",
    zipName: "320 (Guatemala)",
    zipcode: 320,
  },
  {
    id: 92,
    code: "GU",
    name: "Guam",
    zipName: "316 (Guam)",
    zipcode: 316,
  },
  {
    id: 93,
    code: "GW",
    name: "Guinea-Bissau",
    zipName: "624 (Guinea-Bissau)",
    zipcode: 624,
  },
  {
    id: 94,
    code: "GY",
    name: "Guyana",
    zipName: "328 (Guyana)",
    zipcode: 328,
  },
  {
    id: 95,
    code: "HK",
    name: "Hong Kong",
    zipName: "344 (Hong Kong)",
    zipcode: 344,
  },
  {
    id: 96,
    code: "HM",
    name: "Heard Island and McDonald Islands",
    zipName: "334 (Heard Island and McDonald Islands)",
    zipcode: 334,
  },
  {
    id: 97,
    code: "HN",
    name: "Honduras",
    zipName: "340 (Honduras)",
    zipcode: 340,
  },
  {
    id: 98,
    code: "HR",
    name: "Croatia",
    zipName: "191 (Croatia)",
    zipcode: 191,
  },
  {
    id: 99,
    code: "HT",
    name: "Haiti",
    zipName: "332 (Haiti)",
    zipcode: 332,
  },
  {
    id: 100,
    code: "HU",
    name: "Hungary",
    zipName: "348 (Hungary)",
    zipcode: 348,
  },
  {
    id: 101,
    code: "ID",
    name: "Indonesia",
    zipName: "360 (Indonesia)",
    zipcode: 360,
  },
  {
    id: 102,
    code: "IE",
    name: "Ireland",
    zipName: "372 (Ireland)",
    zipcode: 372,
  },
  {
    id: 103,
    code: "IL",
    name: "Israel",
    zipName: "376 (Israel)",
    zipcode: 376,
  },
  {
    id: 104,
    code: "IM",
    name: "Isle of Man",
    zipName: "833 (Isle of Man)",
    zipcode: 833,
  },
  {
    id: 105,
    code: "IN",
    name: "India",
    zipName: "356 (India)",
    zipcode: 356,
  },
  {
    id: 106,
    code: "IO",
    name: "British Indian Ocean Territory",
    zipName: "86 (British Indian Ocean Territory)",
    zipcode: 86,
  },
  {
    id: 107,
    code: "IQ",
    name: "Iraq",
    zipName: "368 (Iraq)",
    zipcode: 368,
  },
  {
    id: 108,
    code: "IR",
    name: "Iran",
    zipName: "364 (Iran)",
    zipcode: 364,
  },
  {
    id: 109,
    code: "IS",
    name: "Iceland",
    zipName: "352 (Iceland)",
    zipcode: 352,
  },
  {
    id: 110,
    code: "IT",
    name: "Italy",
    zipName: "380 (Italy)",
    zipcode: 380,
  },
  {
    id: 111,
    code: "JE",
    name: "Jersey",
    zipName: "832 (Jersey)",
    zipcode: 832,
  },
  {
    id: 112,
    code: "JM",
    name: "Jamaica",
    zipName: "388 (Jamaica)",
    zipcode: 388,
  },
  {
    id: 113,
    code: "JO",
    name: "Jordan",
    zipName: "400 (Jordan)",
    zipcode: 400,
  },
  {
    id: 114,
    code: "JP",
    name: "Japan",
    zipName: "392 (Japan)",
    zipcode: 392,
  },
  {
    id: 115,
    code: "KE",
    name: "Kenya",
    zipName: "404 (Kenya)",
    zipcode: 404,
  },
  {
    id: 116,
    code: "KG",
    name: "Kyrgyzstan",
    zipName: "417 (Kyrgyzstan)",
    zipcode: 417,
  },
  {
    id: 117,
    code: "KH",
    name: "Cambodia",
    zipName: "116 (Cambodia)",
    zipcode: 116,
  },
  {
    id: 118,
    code: "KI",
    name: "Kiribati",
    zipName: "296 (Kiribati)",
    zipcode: 296,
  },
  {
    id: 119,
    code: "KM",
    name: "Comoros",
    zipName: "174 (Comoros)",
    zipcode: 174,
  },
  {
    id: 120,
    code: "KN",
    name: "Saint Kitts and Nevis",
    zipName: "659 (Saint Kitts and Nevis)",
    zipcode: 659,
  },
  {
    id: 121,
    code: "KP",
    name: "Korea (the Democratic People's Republic of)",
    zipName: "408 (Korea (the Democratic People's Republic of))",
    zipcode: 408,
  },
  {
    id: 122,
    code: "KR",
    name: "Korea (the Republic of)",
    zipName: "410 (Korea (the Republic of))",
    zipcode: 410,
  },
  {
    id: 123,
    code: "KW",
    name: "Kuwait",
    zipName: "414 (Kuwait)",
    zipcode: 414,
  },
  {
    id: 124,
    code: "KY",
    name: "Cayman Islands",
    zipName: "136 (Cayman Islands)",
    zipcode: 136,
  },
  {
    id: 125,
    code: "KZ",
    name: "Kazakhstan",
    zipName: "398 (Kazakhstan)",
    zipcode: 398,
  },
  {
    id: 126,
    code: "LA",
    name: "Lao People's Democratic Republic",
    zipName: "418 (Lao People's Democratic Republic)",
    zipcode: 418,
  },
  {
    id: 127,
    code: "LB",
    name: "Lebanon",
    zipName: "422 (Lebanon)",
    zipcode: 422,
  },
  {
    id: 128,
    code: "LC",
    name: "Saint Lucia",
    zipName: "662 (Saint Lucia)",
    zipcode: 662,
  },
  {
    id: 129,
    code: "LI",
    name: "Liechtenstein",
    zipName: "438 (Liechtenstein)",
    zipcode: 438,
  },
  {
    id: 130,
    code: "LK",
    name: "Sri Lanka",
    zipName: "144 (Sri Lanka)",
    zipcode: 144,
  },
  {
    id: 131,
    code: "LR",
    name: "Liberia",
    zipName: "430 (Liberia)",
    zipcode: 430,
  },
  {
    id: 132,
    code: "LS",
    name: "Lesotho",
    zipName: "426 (Lesotho)",
    zipcode: 426,
  },
  {
    id: 133,
    code: "LT",
    name: "Lithuania",
    zipName: "440 (Lithuania)",
    zipcode: 440,
  },
  {
    id: 134,
    code: "LU",
    name: "Luxembourg",
    zipName: "442 (Luxembourg)",
    zipcode: 442,
  },
  {
    id: 135,
    code: "LV",
    name: "Latvia",
    zipName: "428 (Latvia)",
    zipcode: 428,
  },
  {
    id: 136,
    code: "LY",
    name: "Libya",
    zipName: "434 (Libya)",
    zipcode: 434,
  },
  {
    id: 137,
    code: "MA",
    name: "Morocco",
    zipName: "504 (Morocco)",
    zipcode: 504,
  },
  {
    id: 138,
    code: "MC",
    name: "Monaco",
    zipName: "492 (Monaco)",
    zipcode: 492,
  },
  {
    id: 139,
    code: "MD",
    name: "Moldova",
    zipName: "498 (Moldova)",
    zipcode: 498,
  },
  {
    id: 140,
    code: "ME",
    name: "Montenegro",
    zipName: "499 (Montenegro)",
    zipcode: 499,
  },
  {
    id: 141,
    code: "MF",
    name: "Saint Martin (French part)",
    zipName: "663 (Saint Martin (French part))",
    zipcode: 663,
  },
  {
    id: 142,
    code: "MG",
    name: "Madagascar",
    zipName: "450 (Madagascar)",
    zipcode: 450,
  },
  {
    id: 143,
    code: "MH",
    name: "Marshall Islands",
    zipName: "584 (Marshall Islands)",
    zipcode: 584,
  },
  {
    id: 144,
    code: "MK",
    name: "North Macedonia",
    zipName: "807 (North Macedonia)",
    zipcode: 807,
  },
  {
    id: 145,
    code: "ML",
    name: "Mali",
    zipName: "466 (Mali)",
    zipcode: 466,
  },
  {
    id: 146,
    code: "MM",
    name: "Myanmar",
    zipName: "104 (Myanmar)",
    zipcode: 104,
  },
  {
    id: 147,
    code: "MN",
    name: "Mongolia",
    zipName: "496 (Mongolia)",
    zipcode: 496,
  },
  {
    id: 148,
    code: "MO",
    name: "Macao",
    zipName: "446 (Macao)",
    zipcode: 446,
  },
  {
    id: 149,
    code: "MP",
    name: "Northern Mariana Islands",
    zipName: "580 (Northern Mariana Islands)",
    zipcode: 580,
  },
  {
    id: 150,
    code: "MQ",
    name: "Martinique",
    zipName: "474 (Martinique)",
    zipcode: 474,
  },
  {
    id: 151,
    code: "MR",
    name: "Mauritania",
    zipName: "478 (Mauritania)",
    zipcode: 478,
  },
  {
    id: 152,
    code: "MS",
    name: "Montserrat",
    zipName: "500 (Montserrat)",
    zipcode: 500,
  },
  {
    id: 153,
    code: "MT",
    name: "Malta",
    zipName: "470 (Malta)",
    zipcode: 470,
  },
  {
    id: 154,
    code: "MU",
    name: "Mauritius",
    zipName: "480 (Mauritius)",
    zipcode: 480,
  },
  {
    id: 155,
    code: "MV",
    name: "Maldives",
    zipName: "462 (Maldives)",
    zipcode: 462,
  },
  {
    id: 156,
    code: "MW",
    name: "Malawi",
    zipName: "454 (Malawi)",
    zipcode: 454,
  },
  {
    id: 157,
    code: "MX",
    name: "Mexico",
    zipName: "484 (Mexico)",
    zipcode: 484,
  },
  {
    id: 158,
    code: "MY",
    name: "Malaysia",
    zipName: "458 (Malaysia)",
    zipcode: 458,
  },
  {
    id: 159,
    code: "MZ",
    name: "Mozambique",
    zipName: "508 (Mozambique)",
    zipcode: 508,
  },
  {
    id: 160,
    code: "NA",
    name: "Namibia",
    zipName: "516 (Namibia)",
    zipcode: 516,
  },
  {
    id: 161,
    code: "NC",
    name: "New Caledonia",
    zipName: "540 (New Caledonia)",
    zipcode: 540,
  },
  {
    id: 162,
    code: "NE",
    name: "Niger",
    zipName: "562 (Niger)",
    zipcode: 562,
  },
  {
    id: 163,
    code: "NF",
    name: "Norfolk Island",
    zipName: "574 (Norfolk Island)",
    zipcode: 574,
  },
  {
    id: 164,
    code: "NG",
    name: "Nigeria",
    zipName: "566 (Nigeria)",
    zipcode: 566,
  },
  {
    id: 165,
    code: "NI",
    name: "Nicaragua",
    zipName: "558 (Nicaragua)",
    zipcode: 558,
  },
  {
    id: 166,
    code: "NL",
    name: "Netherlands[note 1]",
    zipName: "528 (Netherlands[note 1])",
    zipcode: 528,
  },
  {
    id: 167,
    code: "NO",
    name: "Norway",
    zipName: "578 (Norway)",
    zipcode: 578,
  },
  {
    id: 168,
    code: "NP",
    name: "Nepal",
    zipName: "524 (Nepal)",
    zipcode: 524,
  },
  {
    id: 169,
    code: "NR",
    name: "Nauru",
    zipName: "520 (Nauru)",
    zipcode: 520,
  },
  {
    id: 170,
    code: "NU",
    name: "Niue",
    zipName: "570 (Niue)",
    zipcode: 570,
  },
  {
    id: 171,
    code: "NZ",
    name: "New Zealand",
    zipName: "554 (New Zealand)",
    zipcode: 554,
  },
  {
    id: 172,
    code: "OM",
    name: "Oman",
    zipName: "512 (Oman)",
    zipcode: 512,
  },
  {
    id: 173,
    code: "PA",
    name: "Panama",
    zipName: "591 (Panama)",
    zipcode: 591,
  },
  {
    id: 174,
    code: "PE",
    name: "Peru",
    zipName: "604 (Peru)",
    zipcode: 604,
  },
  {
    id: 175,
    code: "PF",
    name: "French Polynesia",
    zipName: "258 (French Polynesia)",
    zipcode: 258,
  },
  {
    id: 176,
    code: "PG",
    name: "Papua New Guinea",
    zipName: "598 (Papua New Guinea)",
    zipcode: 598,
  },
  {
    id: 177,
    code: "PH",
    name: "Philippines",
    zipName: "608 (Philippines)",
    zipcode: 608,
  },
  {
    id: 178,
    code: "PK",
    name: "Pakistan",
    zipName: "586 (Pakistan)",
    zipcode: 586,
  },
  {
    id: 179,
    code: "PL",
    name: "Poland",
    zipName: "616 (Poland)",
    zipcode: 616,
  },
  {
    id: 180,
    code: "PM",
    name: "Saint Pierre and Miquelon",
    zipName: "666 (Saint Pierre and Miquelon)",
    zipcode: 666,
  },
  {
    id: 181,
    code: "PN",
    name: "Pitcairn",
    zipName: "612 (Pitcairn)",
    zipcode: 612,
  },
  {
    id: 182,
    code: "PR",
    name: "Puerto Rico",
    zipName: "630 (Puerto Rico)",
    zipcode: 630,
  },
  {
    id: 183,
    code: "PS",
    name: "Palestine",
    zipName: "275 (Palestine)",
    zipcode: 275,
  },
  {
    id: 184,
    code: "PT",
    name: "Portugal",
    zipName: "620 (Portugal)",
    zipcode: 620,
  },
  {
    id: 185,
    code: "PW",
    name: "Palau",
    zipName: "585 (Palau)",
    zipcode: 585,
  },
  {
    id: 186,
    code: "PY",
    name: "Paraguay",
    zipName: "600 (Paraguay)",
    zipcode: 600,
  },
  {
    id: 187,
    code: "QA",
    name: "Qatar",
    zipName: "634 (Qatar)",
    zipcode: 634,
  },
  {
    id: 188,
    code: "RE",
    name: "Réunion",
    zipName: "638 (Réunion)",
    zipcode: 638,
  },
  {
    id: 189,
    code: "RO",
    name: "Romania",
    zipName: "642 (Romania)",
    zipcode: 642,
  },
  {
    id: 190,
    code: "RS",
    name: "Serbia",
    zipName: "688 (Serbia)",
    zipcode: 688,
  },
  {
    id: 191,
    code: "RU",
    name: "Russian Federation",
    zipName: "643 (Russian Federation)",
    zipcode: 643,
  },
  {
    id: 192,
    code: "RW",
    name: "Rwanda",
    zipName: "646 (Rwanda)",
    zipcode: 646,
  },
  {
    id: 193,
    code: "SA",
    name: "Saudi Arabia",
    zipName: "682 (Saudi Arabia)",
    zipcode: 682,
  },
  {
    id: 194,
    code: "SB",
    name: "Solomon Islands",
    zipName: "90 (Solomon Islands)",
    zipcode: 90,
  },
  {
    id: 195,
    code: "SC",
    name: "Seychelles",
    zipName: "690 (Seychelles)",
    zipcode: 690,
  },
  {
    id: 196,
    code: "SD",
    name: "Sudan",
    zipName: "729 (Sudan)",
    zipcode: 729,
  },
  {
    id: 197,
    code: "SE",
    name: "Sweden",
    zipName: "752 (Sweden)",
    zipcode: 752,
  },
  {
    id: 198,
    code: "SG",
    name: "Singapore",
    zipName: "702 (Singapore)",
    zipcode: 702,
  },
  {
    id: 199,
    code: "SH",
    name: "Saint Helena",
    zipName: "654 (Saint Helena)",
    zipcode: 654,
  },
  {
    id: 200,
    code: "SI",
    name: "Slovenia",
    zipName: "705 (Slovenia)",
    zipcode: 705,
  },
  {
    id: 201,
    code: "SJ",
    name: "Svalbard and Jan Mayen",
    zipName: "744 (Svalbard and Jan Mayen)",
    zipcode: 744,
  },
  {
    id: 202,
    code: "SK",
    name: "Slovakia",
    zipName: "703 (Slovakia)",
    zipcode: 703,
  },
  {
    id: 203,
    code: "SL",
    name: "Sierra Leone",
    zipName: "694 (Sierra Leone)",
    zipcode: 694,
  },
  {
    id: 204,
    code: "SM",
    name: "San Marino",
    zipName: "674 (San Marino)",
    zipcode: 674,
  },
  {
    id: 205,
    code: "SN",
    name: "Senegal",
    zipName: "686 (Senegal)",
    zipcode: 686,
  },
  {
    id: 206,
    code: "SO",
    name: "Somalia",
    zipName: "706 (Somalia)",
    zipcode: 706,
  },
  {
    id: 207,
    code: "SR",
    name: "Suriname",
    zipName: "740 (Suriname)",
    zipcode: 740,
  },
  {
    id: 208,
    code: "SS",
    name: "South Sudan",
    zipName: "728 (South Sudan)",
    zipcode: 728,
  },
  {
    id: 209,
    code: "ST",
    name: "Sao Tome and Principe",
    zipName: "678 (Sao Tome and Principe)",
    zipcode: 678,
  },
  {
    id: 210,
    code: "SV",
    name: "El Salvador",
    zipName: "222 (El Salvador)",
    zipcode: 222,
  },
  {
    id: 211,
    code: "SX",
    name: "Sint Maarten (Dutch part)",
    zipName: "534 (Sint Maarten (Dutch part))",
    zipcode: 534,
  },
  {
    id: 212,
    code: "SY",
    name: "Syrian Arab Republic",
    zipName: "760 (Syrian Arab Republic)",
    zipcode: 760,
  },
  {
    id: 213,
    code: "SZ",
    name: "Eswatini",
    zipName: "748 (Eswatini)",
    zipcode: 748,
  },
  {
    id: 214,
    code: "TC",
    name: "Turks and Caicos Islands",
    zipName: "796 (Turks and Caicos Islands)",
    zipcode: 796,
  },
  {
    id: 215,
    code: "TD",
    name: "Chad",
    zipName: "148 (Chad)",
    zipcode: 148,
  },
  {
    id: 216,
    code: "TF",
    name: "French Southern Territories",
    zipName: "260 (French Southern Territories)",
    zipcode: 260,
  },
  {
    id: 217,
    code: "TG",
    name: "Togo",
    zipName: "768 (Togo)",
    zipcode: 768,
  },
  {
    id: 218,
    code: "TH",
    name: "Thailand",
    zipName: "764 (Thailand)",
    zipcode: 764,
  },
  {
    id: 219,
    code: "TJ",
    name: "Tajikistan",
    zipName: "762 (Tajikistan)",
    zipcode: 762,
  },
  {
    id: 220,
    code: "TK",
    name: "Tokelau",
    zipName: "772 (Tokelau)",
    zipcode: 772,
  },
  {
    id: 221,
    code: "TL",
    name: "Timor-Leste",
    zipName: "626 (Timor-Leste)",
    zipcode: 626,
  },
  {
    id: 222,
    code: "TM",
    name: "Turkmenistan",
    zipName: "795 (Turkmenistan)",
    zipcode: 795,
  },
  {
    id: 223,
    code: "TN",
    name: "Tunisia",
    zipName: "788 (Tunisia)",
    zipcode: 788,
  },
  {
    id: 224,
    code: "TO",
    name: "Tonga",
    zipName: "776 (Tonga)",
    zipcode: 776,
  },
  {
    id: 225,
    code: "TR",
    name: "Turkey",
    zipName: "792 (Turkey)",
    zipcode: 792,
  },
  {
    id: 226,
    code: "TT",
    name: "Trinidad and Tobago",
    zipName: "780 (Trinidad and Tobago)",
    zipcode: 780,
  },
  {
    id: 227,
    code: "TV",
    name: "Tuvalu",
    zipName: "798 (Tuvalu)",
    zipcode: 798,
  },
  {
    id: 228,
    code: "TW",
    name: "Taiwan",
    zipName: "158 (Taiwan)",
    zipcode: 158,
  },
  {
    id: 229,
    code: "TZ",
    name: "Tanzania",
    zipName: "834 (Tanzania)",
    zipcode: 834,
  },
  {
    id: 230,
    code: "UA",
    name: "Ukraine",
    zipName: "804 (Ukraine)",
    zipcode: 804,
  },
  {
    id: 231,
    code: "UG",
    name: "Uganda",
    zipName: "800 (Uganda)",
    zipcode: 800,
  },
  {
    id: 232,
    code: "UM",
    name: "United States Minor Outlying Islands",
    zipName: "581 (United States Minor Outlying Islands)",
    zipcode: 581,
  },
  {
    id: 233,
    code: "US",
    name: "United States",
    zipName: "840 (United States)",
    zipcode: 840,
  },
  {
    id: 234,
    code: "UY",
    name: "Uruguay",
    zipName: "858 (Uruguay)",
    zipcode: 858,
  },
  {
    id: 235,
    code: "UZ",
    name: "Uzbekistan",
    zipName: "860 (Uzbekistan)",
    zipcode: 860,
  },
  {
    id: 236,
    code: "VA",
    name: "Holy See (Vatican City State)",
    zipName: "336 (Holy See (Vatican City State))",
    zipcode: 336,
  },
  {
    id: 237,
    code: "VC",
    name: "Saint Vincent and the Grenadines",
    zipName: "670 (Saint Vincent and the Grenadines)",
    zipcode: 670,
  },
  {
    id: 238,
    code: "VE",
    name: "Venezuela",
    zipName: "862 (Venezuela)",
    zipcode: 862,
  },
  {
    id: 239,
    code: "VG",
    name: "Virgin Islands",
    zipName: "92 (Virgin Islands)",
    zipcode: 92,
  },
  {
    id: 240,
    code: "VI",
    name: "Virgin Islands",
    zipName: "850 (Virgin Islands)",
    zipcode: 850,
  },
  {
    id: 241,
    code: "VN",
    name: "Viet Nam",
    zipName: "704 (Viet Nam)",
    zipcode: 704,
  },
  {
    id: 242,
    code: "VU",
    name: "Vanuatu",
    zipName: "548 (Vanuatu)",
    zipcode: 548,
  },
  {
    id: 243,
    code: "WF",
    name: "Wallis and Futuna",
    zipName: "876 (Wallis and Futuna)",
    zipcode: 876,
  },
  {
    id: 244,
    code: "WS",
    name: "Samoa",
    zipName: "882 (Samoa)",
    zipcode: 882,
  },
  {
    id: 245,
    code: "YE",
    name: "Yemen",
    zipName: "887 (Yemen)",
    zipcode: 887,
  },
  {
    id: 246,
    code: "YT",
    name: "Mayotte",
    zipName: "175 (Mayotte)",
    zipcode: 175,
  },
  {
    id: 247,
    code: "ZA",
    name: "South Africa",
    zipName: "710 (South Africa)",
    zipcode: 710,
  },
  {
    id: 248,
    code: "ZM",
    name: "Zambia",
    zipName: "894 (Zambia)",
    zipcode: 894,
  },
  {
    id: 249,
    code: "ZW",
    name: "Zimbabwe",
    zipName: "716 (Zimbabwe)",
    zipcode: 716,
  },
];

export const option_price_designation = [{ label: "Business Price", value: 1 }];

export const option_sales_channel = [
  { label: "Amazon.com", value: "Amazon.com" },
];

export const option_bool_true_false = [
  { label: "true", value: 1 },
  { label: "false", value: 0 },
];

export const option_bool_yes_no = [
  { label: "Yes", value: 1 },
  { label: "No", value: 0 },
];

export const option_store_name = [{ label: "Wayfair", value: "Wayfair" }];

export const option_order_status = [
  { label: "NEW", value: "NEW" },
  { label: "Pending Ship Confirmation", value: "Pending Ship Confirmation" },
  { label: "Ready For Pickup", value: "Ready For Pickup" },
];

export const option_ship_method = [
  { label: "Small Parcel", value: "Small Parcel" },
];

export const option_ship_speed = [
  { label: "FedEx Home Delivery", value: "FedEx Home Delivery" },
  { label: "Ground", value: "Ground" },
];

export const option_schedule_type = [
  { label: "15 min", value: 1 },
  { label: "30 min", value: 2 },
  { label: "1 hour", value: 3 },
  { label: "1 day", value: 4 },
];

export const option_currency = [
  {
    label: "AFN",
    value: "AFN",
  },
  {
    label: "EUR",
    value: "EUR",
  },
  {
    label: "ALL",
    value: "ALL",
  },
  {
    label: "DZD",
    value: "DZD",
  },
  {
    label: "USD",
    value: "USD",
  },
  {
    label: "EUR",
    value: "EUR",
  },
  {
    label: "AOA",
    value: "AOA",
  },
  {
    label: "XCD",
    value: "XCD",
  },
  {
    label: "XCD",
    value: "XCD",
  },
  {
    label: "ARS",
    value: "ARS",
  },
  {
    label: "AMD",
    value: "AMD",
  },
  {
    label: "AWG",
    value: "AWG",
  },
  {
    label: "AUD",
    value: "AUD",
  },
  {
    label: "EUR",
    value: "EUR",
  },
  {
    label: "AZN",
    value: "AZN",
  },
  {
    label: "BHD",
    value: "BHD",
  },
  {
    label: "BSD",
    value: "BSD",
  },
  {
    label: "BDT",
    value: "BDT",
  },
  {
    label: "BBD",
    value: "BBD",
  },
  {
    label: "BYN",
    value: "BYN",
  },
  {
    label: "EUR",
    value: "EUR",
  },
  {
    label: "BZD",
    value: "BZD",
  },
  {
    label: "XOF",
    value: "XOF",
  },
  {
    label: "BMD",
    value: "BMD",
  },
  {
    label: "INR",
    value: "INR",
  },
  {
    label: "BTN",
    value: "BTN",
  },
  {
    label: "BOB",
    value: "BOB",
  },
  {
    label: "BOV",
    value: "BOV",
  },
  {
    label: "USD",
    value: "USD",
  },
  {
    label: "BAM",
    value: "BAM",
  },
  {
    label: "BWP",
    value: "BWP",
  },
  {
    label: "NOK",
    value: "NOK",
  },
  {
    label: "BRL",
    value: "BRL",
  },
  {
    label: "USD",
    value: "USD",
  },
  {
    label: "BND",
    value: "BND",
  },
  {
    label: "BGN",
    value: "BGN",
  },
  {
    label: "XOF",
    value: "XOF",
  },
  {
    label: "BIF",
    value: "BIF",
  },
  {
    label: "CVE",
    value: "CVE",
  },
  {
    label: "KHR",
    value: "KHR",
  },
  {
    label: "XAF",
    value: "XAF",
  },
  {
    label: "CAD",
    value: "CAD",
  },
  {
    label: "KYD",
    value: "KYD",
  },
  {
    label: "XAF",
    value: "XAF",
  },
  {
    label: "XAF",
    value: "XAF",
  },
  {
    label: "CLP",
    value: "CLP",
  },
  {
    label: "CLF",
    value: "CLF",
  },
  {
    label: "CNY",
    value: "CNY",
  },
  {
    label: "AUD",
    value: "AUD",
  },
  {
    label: "AUD",
    value: "AUD",
  },
  {
    label: "COP",
    value: "COP",
  },
  {
    label: "COU",
    value: "COU",
  },
  {
    label: "KMF",
    value: "KMF",
  },
  {
    label: "CDF",
    value: "CDF",
  },
  {
    label: "XAF",
    value: "XAF",
  },
  {
    label: "NZD",
    value: "NZD",
  },
  {
    label: "CRC",
    value: "CRC",
  },
  {
    label: "XOF",
    value: "XOF",
  },
  {
    label: "HRK",
    value: "HRK",
  },
  {
    label: "CUP",
    value: "CUP",
  },
  {
    label: "CUC",
    value: "CUC",
  },
  {
    label: "ANG",
    value: "ANG",
  },
  {
    label: "EUR",
    value: "EUR",
  },
  {
    label: "CZK",
    value: "CZK",
  },
  {
    label: "DKK",
    value: "DKK",
  },
  {
    label: "DJF",
    value: "DJF",
  },
  {
    label: "XCD",
    value: "XCD",
  },
  {
    label: "DOP",
    value: "DOP",
  },
  {
    label: "USD",
    value: "USD",
  },
  {
    label: "EGP",
    value: "EGP",
  },
  {
    label: "SVC",
    value: "SVC",
  },
  {
    label: "USD",
    value: "USD",
  },
  {
    label: "XAF",
    value: "XAF",
  },
  {
    label: "ERN",
    value: "ERN",
  },
  {
    label: "EUR",
    value: "EUR",
  },
  {
    label: "SZL",
    value: "SZL",
  },
  {
    label: "ETB",
    value: "ETB",
  },
  {
    label: "EUR",
    value: "EUR",
  },
  {
    label: "FKP",
    value: "FKP",
  },
  {
    label: "DKK",
    value: "DKK",
  },
  {
    label: "FJD",
    value: "FJD",
  },
  {
    label: "EUR",
    value: "EUR",
  },
  {
    label: "EUR",
    value: "EUR",
  },
  {
    label: "EUR",
    value: "EUR",
  },
  {
    label: "XPF",
    value: "XPF",
  },
  {
    label: "EUR",
    value: "EUR",
  },
  {
    label: "XAF",
    value: "XAF",
  },
  {
    label: "GMD",
    value: "GMD",
  },
  {
    label: "GEL",
    value: "GEL",
  },
  {
    label: "EUR",
    value: "EUR",
  },
  {
    label: "GHS",
    value: "GHS",
  },
  {
    label: "GIP",
    value: "GIP",
  },
  {
    label: "EUR",
    value: "EUR",
  },
  {
    label: "DKK",
    value: "DKK",
  },
  {
    label: "XCD",
    value: "XCD",
  },
  {
    label: "EUR",
    value: "EUR",
  },
  {
    label: "USD",
    value: "USD",
  },
  {
    label: "GTQ",
    value: "GTQ",
  },
  {
    label: "GBP",
    value: "GBP",
  },
  {
    label: "GNF",
    value: "GNF",
  },
  {
    label: "XOF",
    value: "XOF",
  },
  {
    label: "GYD",
    value: "GYD",
  },
  {
    label: "HTG",
    value: "HTG",
  },
  {
    label: "USD",
    value: "USD",
  },
  {
    label: "AUD",
    value: "AUD",
  },
  {
    label: "EUR",
    value: "EUR",
  },
  {
    label: "HNL",
    value: "HNL",
  },
  {
    label: "HKD",
    value: "HKD",
  },
  {
    label: "HUF",
    value: "HUF",
  },
  {
    label: "ISK",
    value: "ISK",
  },
  {
    label: "INR",
    value: "INR",
  },
  {
    label: "IDR",
    value: "IDR",
  },
  {
    label: "XDR",
    value: "XDR",
  },
  {
    label: "IRR",
    value: "IRR",
  },
  {
    label: "IQD",
    value: "IQD",
  },
  {
    label: "EUR",
    value: "EUR",
  },
  {
    label: "GBP",
    value: "GBP",
  },
  {
    label: "ILS",
    value: "ILS",
  },
  {
    label: "EUR",
    value: "EUR",
  },
  {
    label: "JMD",
    value: "JMD",
  },
  {
    label: "JPY",
    value: "JPY",
  },
  {
    label: "GBP",
    value: "GBP",
  },
  {
    label: "JOD",
    value: "JOD",
  },
  {
    label: "KZT",
    value: "KZT",
  },
  {
    label: "KES",
    value: "KES",
  },
  {
    label: "AUD",
    value: "AUD",
  },
  {
    label: "KPW",
    value: "KPW",
  },
  {
    label: "KRW",
    value: "KRW",
  },
  {
    label: "KWD",
    value: "KWD",
  },
  {
    label: "KGS",
    value: "KGS",
  },
  {
    label: "LAK",
    value: "LAK",
  },
  {
    label: "EUR",
    value: "EUR",
  },
  {
    label: "LBP",
    value: "LBP",
  },
  {
    label: "LSL",
    value: "LSL",
  },
  {
    label: "ZAR",
    value: "ZAR",
  },
  {
    label: "LRD",
    value: "LRD",
  },
  {
    label: "LYD",
    value: "LYD",
  },
  {
    label: "CHF",
    value: "CHF",
  },
  {
    label: "EUR",
    value: "EUR",
  },
  {
    label: "EUR",
    value: "EUR",
  },
  {
    label: "MOP",
    value: "MOP",
  },
  {
    label: "MKD",
    value: "MKD",
  },
  {
    label: "MGA",
    value: "MGA",
  },
  {
    label: "MWK",
    value: "MWK",
  },
  {
    label: "MYR",
    value: "MYR",
  },
  {
    label: "MVR",
    value: "MVR",
  },
  {
    label: "XOF",
    value: "XOF",
  },
  {
    label: "EUR",
    value: "EUR",
  },
  {
    label: "USD",
    value: "USD",
  },
  {
    label: "EUR",
    value: "EUR",
  },
  {
    label: "MRU",
    value: "MRU",
  },
  {
    label: "MUR",
    value: "MUR",
  },
  {
    label: "EUR",
    value: "EUR",
  },
  {
    label: "XUA",
    value: "XUA",
  },
  {
    label: "MXN",
    value: "MXN",
  },
  {
    label: "MXV",
    value: "MXV",
  },
  {
    label: "USD",
    value: "USD",
  },
  {
    label: "MDL",
    value: "MDL",
  },
  {
    label: "EUR",
    value: "EUR",
  },
  {
    label: "MNT",
    value: "MNT",
  },
  {
    label: "EUR",
    value: "EUR",
  },
  {
    label: "XCD",
    value: "XCD",
  },
  {
    label: "MAD",
    value: "MAD",
  },
  {
    label: "MZN",
    value: "MZN",
  },
  {
    label: "MMK",
    value: "MMK",
  },
  {
    label: "NAD",
    value: "NAD",
  },
  {
    label: "ZAR",
    value: "ZAR",
  },
  {
    label: "AUD",
    value: "AUD",
  },
  {
    label: "NPR",
    value: "NPR",
  },
  {
    label: "EUR",
    value: "EUR",
  },
  {
    label: "XPF",
    value: "XPF",
  },
  {
    label: "NZD",
    value: "NZD",
  },
  {
    label: "NIO",
    value: "NIO",
  },
  {
    label: "XOF",
    value: "XOF",
  },
  {
    label: "NGN",
    value: "NGN",
  },
  {
    label: "NZD",
    value: "NZD",
  },
  {
    label: "AUD",
    value: "AUD",
  },
  {
    label: "USD",
    value: "USD",
  },
  {
    label: "NOK",
    value: "NOK",
  },
  {
    label: "OMR",
    value: "OMR",
  },
  {
    label: "PKR",
    value: "PKR",
  },
  {
    label: "USD",
    value: "USD",
  },
  {
    label: "PAB",
    value: "PAB",
  },
  {
    label: "USD",
    value: "USD",
  },
  {
    label: "PGK",
    value: "PGK",
  },
  {
    label: "PYG",
    value: "PYG",
  },
  {
    label: "PEN",
    value: "PEN",
  },
  {
    label: "PHP",
    value: "PHP",
  },
  {
    label: "NZD",
    value: "NZD",
  },
  {
    label: "PLN",
    value: "PLN",
  },
  {
    label: "EUR",
    value: "EUR",
  },
  {
    label: "USD",
    value: "USD",
  },
  {
    label: "QAR",
    value: "QAR",
  },
  {
    label: "EUR",
    value: "EUR",
  },
  {
    label: "RON",
    value: "RON",
  },
  {
    label: "RUB",
    value: "RUB",
  },
  {
    label: "RWF",
    value: "RWF",
  },
  {
    label: "EUR",
    value: "EUR",
  },
  {
    label: "SHP",
    value: "SHP",
  },
  {
    label: "XCD",
    value: "XCD",
  },
  {
    label: "XCD",
    value: "XCD",
  },
  {
    label: "EUR",
    value: "EUR",
  },
  {
    label: "EUR",
    value: "EUR",
  },
  {
    label: "XCD",
    value: "XCD",
  },
  {
    label: "WST",
    value: "WST",
  },
  {
    label: "EUR",
    value: "EUR",
  },
  {
    label: "STN",
    value: "STN",
  },
  {
    label: "SAR",
    value: "SAR",
  },
  {
    label: "XOF",
    value: "XOF",
  },
  {
    label: "RSD",
    value: "RSD",
  },
  {
    label: "SCR",
    value: "SCR",
  },
  {
    label: "SLL",
    value: "SLL",
  },
  {
    label: "SGD",
    value: "SGD",
  },
  {
    label: "ANG",
    value: "ANG",
  },
  {
    label: "XSU",
    value: "XSU",
  },
  {
    label: "EUR",
    value: "EUR",
  },
  {
    label: "EUR",
    value: "EUR",
  },
  {
    label: "SBD",
    value: "SBD",
  },
  {
    label: "SOS",
    value: "SOS",
  },
  {
    label: "ZAR",
    value: "ZAR",
  },
  {
    label: "SSP",
    value: "SSP",
  },
  {
    label: "EUR",
    value: "EUR",
  },
  {
    label: "LKR",
    value: "LKR",
  },
  {
    label: "SDG",
    value: "SDG",
  },
  {
    label: "SRD",
    value: "SRD",
  },
  {
    label: "NOK",
    value: "NOK",
  },
  {
    label: "SEK",
    value: "SEK",
  },
  {
    label: "CHF",
    value: "CHF",
  },
  {
    label: "CHE",
    value: "CHE",
  },
  {
    label: "CHW",
    value: "CHW",
  },
  {
    label: "SYP",
    value: "SYP",
  },
  {
    label: "TWD",
    value: "TWD",
  },
  {
    label: "TJS",
    value: "TJS",
  },
  {
    label: "TZS",
    value: "TZS",
  },
  {
    label: "THB",
    value: "THB",
  },
  {
    label: "USD",
    value: "USD",
  },
  {
    label: "XOF",
    value: "XOF",
  },
  {
    label: "NZD",
    value: "NZD",
  },
  {
    label: "TOP",
    value: "TOP",
  },
  {
    label: "TTD",
    value: "TTD",
  },
  {
    label: "TND",
    value: "TND",
  },
  {
    label: "TRY",
    value: "TRY",
  },
  {
    label: "TMT",
    value: "TMT",
  },
  {
    label: "USD",
    value: "USD",
  },
  {
    label: "AUD",
    value: "AUD",
  },
  {
    label: "UGX",
    value: "UGX",
  },
  {
    label: "UAH",
    value: "UAH",
  },
  {
    label: "AED",
    value: "AED",
  },
  {
    label: "GBP",
    value: "GBP",
  },
  {
    label: "USD",
    value: "USD",
  },
  {
    label: "USD",
    value: "USD",
  },
  {
    label: "USN",
    value: "USN",
  },
  {
    label: "UYU",
    value: "UYU",
  },
  {
    label: "UYI",
    value: "UYI",
  },
  {
    label: "UYW",
    value: "UYW",
  },
  {
    label: "UZS",
    value: "UZS",
  },
  {
    label: "VUV",
    value: "VUV",
  },
  {
    label: "VES",
    value: "VES",
  },
  {
    label: "VND",
    value: "VND",
  },
  {
    label: "USD",
    value: "USD",
  },
  {
    label: "USD",
    value: "USD",
  },
  {
    label: "XPF",
    value: "XPF",
  },
  {
    label: "MAD",
    value: "MAD",
  },
  {
    label: "YER",
    value: "YER",
  },
  {
    label: "ZMW",
    value: "ZMW",
  },
  {
    label: "ZWL",
    value: "ZWL",
  },
  {
    label: "XBA",
    value: "XBA",
  },
  {
    label: "XBB",
    value: "XBB",
  },
  {
    label: "XBC",
    value: "XBC",
  },
  {
    label: "XBD",
    value: "XBD",
  },
  {
    label: "XTS",
    value: "XTS",
  },
  {
    label: "XXX",
    value: "XXX",
  },
  {
    label: "XAU",
    value: "XAU",
  },
  {
    label: "XPD",
    value: "XPD",
  },
  {
    label: "XPT",
    value: "XPT",
  },
  {
    label: "XAG",
    value: "XAG",
  },
  {
    label: "AFA",
    value: "AFA",
  },
  {
    label: "FIM",
    value: "FIM",
  },
  {
    label: "ALK",
    value: "ALK",
  },
  {
    label: "ADP",
    value: "ADP",
  },
  {
    label: "ESP",
    value: "ESP",
  },
  {
    label: "FRF",
    value: "FRF",
  },
  {
    label: "AOK",
    value: "AOK",
  },
  {
    label: "AON",
    value: "AON",
  },
  {
    label: "AOR",
    value: "AOR",
  },
  {
    label: "ARA",
    value: "ARA",
  },
  {
    label: "ARP",
    value: "ARP",
  },
  {
    label: "ARY",
    value: "ARY",
  },
  {
    label: "RUR",
    value: "RUR",
  },
  {
    label: "ATS",
    value: "ATS",
  },
  {
    label: "AYM",
    value: "AYM",
  },
  {
    label: "AZM",
    value: "AZM",
  },
  {
    label: "RUR",
    value: "RUR",
  },
  {
    label: "BYB",
    value: "BYB",
  },
  {
    label: "BYR",
    value: "BYR",
  },
  {
    label: "RUR",
    value: "RUR",
  },
  {
    label: "BEC",
    value: "BEC",
  },
  {
    label: "BEF",
    value: "BEF",
  },
  {
    label: "BEL",
    value: "BEL",
  },
  {
    label: "BOP",
    value: "BOP",
  },
  {
    label: "BAD",
    value: "BAD",
  },
  {
    label: "BRB",
    value: "BRB",
  },
  {
    label: "BRC",
    value: "BRC",
  },
  {
    label: "BRE",
    value: "BRE",
  },
  {
    label: "BRN",
    value: "BRN",
  },
  {
    label: "BRR",
    value: "BRR",
  },
  {
    label: "BGJ",
    value: "BGJ",
  },
  {
    label: "BGK",
    value: "BGK",
  },
  {
    label: "BGL",
    value: "BGL",
  },
  {
    label: "BUK",
    value: "BUK",
  },
  {
    label: "HRD",
    value: "HRD",
  },
  {
    label: "HRK",
    value: "HRK",
  },
  {
    label: "CYP",
    value: "CYP",
  },
  {
    label: "CSJ",
    value: "CSJ",
  },
  {
    label: "CSK",
    value: "CSK",
  },
  {
    label: "ECS",
    value: "ECS",
  },
  {
    label: "ECV",
    value: "ECV",
  },
  {
    label: "GQE",
    value: "GQE",
  },
  {
    label: "EEK",
    value: "EEK",
  },
  {
    label: "XEU",
    value: "XEU",
  },
  {
    label: "FIM",
    value: "FIM",
  },
  {
    label: "FRF",
    value: "FRF",
  },
  {
    label: "FRF",
    value: "FRF",
  },
  {
    label: "FRF",
    value: "FRF",
  },
  {
    label: "GEK",
    value: "GEK",
  },
  {
    label: "RUR",
    value: "RUR",
  },
  {
    label: "DDM",
    value: "DDM",
  },
  {
    label: "DEM",
    value: "DEM",
  },
  {
    label: "GHC",
    value: "GHC",
  },
  {
    label: "GHP",
    value: "GHP",
  },
  {
    label: "GRD",
    value: "GRD",
  },
  {
    label: "FRF",
    value: "FRF",
  },
  {
    label: "GNE",
    value: "GNE",
  },
  {
    label: "GNS",
    value: "GNS",
  },
  {
    label: "GWE",
    value: "GWE",
  },
  {
    label: "GWP",
    value: "GWP",
  },
  {
    label: "ITL",
    value: "ITL",
  },
  {
    label: "ISJ",
    value: "ISJ",
  },
  {
    label: "IEP",
    value: "IEP",
  },
  {
    label: "ILP",
    value: "ILP",
  },
  {
    label: "ILR",
    value: "ILR",
  },
  {
    label: "ITL",
    value: "ITL",
  },
  {
    label: "RUR",
    value: "RUR",
  },
  {
    label: "RUR",
    value: "RUR",
  },
  {
    label: "LAJ",
    value: "LAJ",
  },
  {
    label: "LVL",
    value: "LVL",
  },
  {
    label: "LVR",
    value: "LVR",
  },
  {
    label: "LSM",
    value: "LSM",
  },
  {
    label: "ZAL",
    value: "ZAL",
  },
  {
    label: "LTL",
    value: "LTL",
  },
  {
    label: "LTT",
    value: "LTT",
  },
  {
    label: "LUC",
    value: "LUC",
  },
  {
    label: "LUF",
    value: "LUF",
  },
  {
    label: "LUL",
    value: "LUL",
  },
  {
    label: "MGF",
    value: "MGF",
  },
  {
    label: "MWK",
    value: "MWK",
  },
  {
    label: "MVQ",
    value: "MVQ",
  },
  {
    label: "MLF",
    value: "MLF",
  },
  {
    label: "MTL",
    value: "MTL",
  },
  {
    label: "MTP",
    value: "MTP",
  },
  {
    label: "FRF",
    value: "FRF",
  },
  {
    label: "MRO",
    value: "MRO",
  },
  {
    label: "FRF",
    value: "FRF",
  },
  {
    label: "MXP",
    value: "MXP",
  },
  {
    label: "RUR",
    value: "RUR",
  },
  {
    label: "FRF",
    value: "FRF",
  },
  {
    label: "MZE",
    value: "MZE",
  },
  {
    label: "MZM",
    value: "MZM",
  },
  {
    label: "NLG",
    value: "NLG",
  },
  {
    label: "ANG",
    value: "ANG",
  },
  {
    label: "NIC",
    value: "NIC",
  },
  {
    label: "PEH",
    value: "PEH",
  },
  {
    label: "PEI",
    value: "PEI",
  },
  {
    label: "PEN",
    value: "PEN",
  },
  {
    label: "PES",
    value: "PES",
  },
  {
    label: "PLZ",
    value: "PLZ",
  },
  {
    label: "PTE",
    value: "PTE",
  },
  {
    label: "FRF",
    value: "FRF",
  },
  {
    label: "ROK",
    value: "ROK",
  },
  {
    label: "ROL",
    value: "ROL",
  },
  {
    label: "RON",
    value: "RON",
  },
  {
    label: "RUR",
    value: "RUR",
  },
  {
    label: "FRF",
    value: "FRF",
  },
  {
    label: "FRF",
    value: "FRF",
  },
  {
    label: "FRF",
    value: "FRF",
  },
  {
    label: "ITL",
    value: "ITL",
  },
  {
    label: "STD",
    value: "STD",
  },
  {
    label: "CSD",
    value: "CSD",
  },
  {
    label: "EUR",
    value: "EUR",
  },
  {
    label: "SKK",
    value: "SKK",
  },
  {
    label: "SIT",
    value: "SIT",
  },
  {
    label: "ZAL",
    value: "ZAL",
  },
  {
    label: "SDG",
    value: "SDG",
  },
  {
    label: "RHD",
    value: "RHD",
  },
  {
    label: "ESA",
    value: "ESA",
  },
  {
    label: "ESB",
    value: "ESB",
  },
  {
    label: "ESP",
    value: "ESP",
  },
  {
    label: "SDD",
    value: "SDD",
  },
  {
    label: "SDP",
    value: "SDP",
  },
  {
    label: "SRG",
    value: "SRG",
  },
  {
    label: "SZL",
    value: "SZL",
  },
  {
    label: "CHC",
    value: "CHC",
  },
  {
    label: "RUR",
    value: "RUR",
  },
  {
    label: "TJR",
    value: "TJR",
  },
  {
    label: "IDR",
    value: "IDR",
  },
  {
    label: "TPE",
    value: "TPE",
  },
  {
    label: "TRL",
    value: "TRL",
  },
  {
    label: "TRY",
    value: "TRY",
  },
  {
    label: "RUR",
    value: "RUR",
  },
  {
    label: "TMM",
    value: "TMM",
  },
  {
    label: "UGS",
    value: "UGS",
  },
  {
    label: "UGW",
    value: "UGW",
  },
  {
    label: "UAK",
    value: "UAK",
  },
  {
    label: "SUR",
    value: "SUR",
  },
  {
    label: "USS",
    value: "USS",
  },
  {
    label: "UYN",
    value: "UYN",
  },
  {
    label: "UYP",
    value: "UYP",
  },
  {
    label: "RUR",
    value: "RUR",
  },
  {
    label: "VEB",
    value: "VEB",
  },
  {
    label: "VEF",
    value: "VEF",
  },
  {
    label: "VEF",
    value: "VEF",
  },
  {
    label: "VEF",
    value: "VEF",
  },
  {
    label: "VNC",
    value: "VNC",
  },
  {
    label: "YDD",
    value: "YDD",
  },
  {
    label: "YUD",
    value: "YUD",
  },
  {
    label: "YUM",
    value: "YUM",
  },
  {
    label: "YUN",
    value: "YUN",
  },
  {
    label: "ZRN",
    value: "ZRN",
  },
  {
    label: "ZRZ",
    value: "ZRZ",
  },
  {
    label: "ZMK",
    value: "ZMK",
  },
  {
    label: "ZWC",
    value: "ZWC",
  },
  {
    label: "ZWD",
    value: "ZWD",
  },
  {
    label: "ZWD",
    value: "ZWD",
  },
  {
    label: "ZWN",
    value: "ZWN",
  },
  {
    label: "ZWR",
    value: "ZWR",
  },
  {
    label: "XFO",
    value: "XFO",
  },
  {
    label: "XRE",
    value: "XRE",
  },
  {
    label: "XFU",
    value: "XFU",
  },
];
